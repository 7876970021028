.c-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: var(--color-white);
  z-index: 4;

  &-wrap {
    padding: 18px 50px;
    display: flex;
    justify-content: space-between;

    @include s-1440 {
      padding: 12px 40px;
    }

    @include s-1024 {
      padding: 10px 20px;
    }

    @include s-640 {
      padding: 2px 15px;
    }
  }

  &-logo {
    width: 100%;
    max-width: 248px;
    @include s-1440 {
      max-width: 214px;
    }
    @include s-1024 {
      max-width: 207px;
    }
    @include s-640 {
    }
    img {
      width: 100%;
    }
  }

  &-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    width: auto;
  }

  &-menu {
    display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // flex: 1;
    @include s-1024 {
      display: none;
    }

    &-ul {
      display: flex;
    }

    & li {
      padding-right: 40px;
      &:last-of-type {
        display: none;
      }
    }
    a {
      color: var(--color-black);
      text-transform: uppercase;
      font-family: var(--font-bold-tele);
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.05em;
      position: relative;
      @include s-1920 {
        font-size: 16px;
      }
      @include s-1440 {
        font-size: 12px;
      }
      &:after {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: var(--color-black);
        opacity: 0;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
    &-btns {
      max-width: 240px;
      width: 100%;
      @include s-1920 {
        max-width: 220px;
      }
      @include s-1440 {
        max-width: 160px;
      }
      @include s-1024 {
        max-width: 32px;
      }
      .btn-menu {
        // height: 44px;
        max-width: 240px;
        @include s-1920 {
          max-width: 220px;
        }
        @include s-1440 {
          max-width: 160px;
        }
        @include s-1024 {
          max-width: 32px;
        }
        .btn-wrap {
          padding: 12px 10px 11px;
          @include s-1920 {
            padding: 11px 10px 10px;
          }
          @include s-1440 {
            padding: 9px 10px 9px;
          }
        }

        @include s-1024 {
          display: none;
        }
      }
      .btn-mobile {
        display: none;
        padding: 0;
        width: 32px;
        height: 24px;
        @include s-1024 {
          display: block;
        }
        @include s-640 {
          width: 32px;
          height: 18px;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &-modal {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    & .btn-close {
      width: 52px;
      height: 52px;
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 10;
      padding: 0;
      @include s-1920 {
        width: 42px;
        height: 42px;
        top: 20px;
        right: 20px;
      }

      @include s-1440 {
        width: 38px;
        height: 38px;
      }

      @include s-1024 {
        width: 32px;
        height: 32px;
      }
    }
    svg{
      width: 100%;
      height: 100%;
    }
    &--bg {
      position: absolute;
      top: 0;
      left: 0;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      justify-content: flex-end;
      opacity: 0;
      // visibility: hidden;
      z-index: 3;
      display: flex;
      transition: all ease 0.6s;
    }

    &-box {
      overflow-y: auto;
      transform: translateX(100%);
      transition: all ease 0.6s;
      // height: 100%;
      // width: 25vw;
      margin: 0 0 0 auto;
      background-color: #fff;
      background-color: var(--color-white);
      height: 100%;
      overflow: auto;
      width: 850px;
      z-index: 3;
      position: relative;
      @include s-1920 {
        width: 640px;
      }
      @include s-1440 {
        width: 480px;
      }
      @include s-1024 {
        width: 400px;
      }
      @include s-640 {
        width: 325px;
      }
    }
    &-wrap {
      padding-bottom: 80px;
      // background-color: var(--color-white);
      // height: 100%;
      // overflow: auto;
      // width: 850px;
      // padding: 285px 180px 120px;
      // @include s-1920 {
      //   width: 640px;
      // }
      // @include s-1440 {
      //   width: 480px;
      // }
      // @include s-1024 {
      //   width: 400px;
      // }
      // @include s-640 {
      //   width: 325px;
      // }
    }
    &-top {
      padding: 285px 80px 0 180px;
      @include s-1920{
        padding: 110px 80px 0 130px;
      }
      @include s-1440{
        padding: 110px 80px 0 100px;
      }
      @include s-1024{
        padding: 110px 80px 0 90px;
      }
      @include s-768{
        padding: 110px 90px 0 90px;
      }
      @include s-640{
        padding: 110px 74px 0 74px;
      }
      .block-menu {
      }
      ul {
      }
      li {
        padding-bottom: 20px;
        &:last-of-type {
          padding-bottom: 0;
        }
      }
      a {
        color: var(--color-black);
        font-size: 64px;
        font-family: var(--font-regular-tele);
        line-height: 120%;
        @include s-1920 {
          font-size: 48px;
        }
        @include s-1440 {
          font-size: 38px;
        }
        @include s-1024 {
          font-size: 32px;
        }
        @include s-640 {
          font-size: 26px;
        }
      }
    }
    &-middle {
      padding: 110px 80px 0 180px;
      @include s-1920{
        padding: 80px 80px 0 130px;
      }
      @include s-1440{
        padding: 100px 80px 0 100px;
      }
      @include s-1024{
        padding: 35px 80px 0 90px;
      }
      @include s-768{
        padding: 55px 90px 0 90px;
      }
      @include s-640{
        padding: 40px 74px 0 74px;
      }
      .block-menu {
      }
      ul {
      }
      li {
        padding-bottom: 15px;
        &:last-of-type {
          padding-bottom: 0;
        }
      }
      a {
        color: var(--color-black);
        font-size: 24px;
        line-height: 145%;
        @include s-1920 {
          font-size: 20px;
        }
        @include s-1440 {
          font-size: 18px;
        }
        @include s-1024 {
          font-size: 16px;
        }
        @include s-640 {
          font-size: 14px;
        }
      }
    }
    &-bottom {
      padding: 200px 30px 0;
      @include s-1920{
        padding: 160px 30px 0;
      }
      @include s-1440{
        padding: 200px 30px 0;
      } 
      @include s-1024{
        padding: 90px 30px 0;
      }
      @include s-768{
        padding: 220px 30px 0;
      }
      @include s-640 {
        font-size: 14px;
        padding: 80px 30px 0;
      }
      // @include s-768{
      //   // padding: 130px 80px 80px 90px;
      // }
      // @include s-640{
      //   // padding: 110px 80px 80px 90px;
      // }
      // @include s-1920 {
      //   padding-top: 160px;
      // }
      // @include s-1440 {
      //   padding-top: 200px;
      // }
      // @include s-1024 {
      //   padding-top: 88px;
      // }

      .block-btns {
        width: 100%;
        max-width: 490px;
        margin: 0 auto;
        @include s-1920 {
          max-width: 314px;
        }
        @include s-1440 {
          max-width: 280px;
        }
        @include s-1024 {
          max-width: 300px;
        }
        @include s-640 {
          font-size: 14px;
          max-width: 100%;
        }
      }
      .btn-border {
        width: 100%;
      }
    }
    // &-enter {
    //   transform: translateX(100%);
    // }

    &-enter-active {
      & .c-header-modal-box {
        transform: translateX(0);
      }

      & .c-header-modal--bg {
        opacity: 0.6;
      }
    }

    &-enter-done {
      & .c-header-modal-box {
        transform: translateX(0);
      }

      & .c-header-modal--bg {
        opacity: 0.6;
      }
    }

    // &-exit {
    //     transform: translateX(100%);
    // }

    &-exit-active {
      & .c-header-modal-box {
        transform: translateX(125%);
      }

      & .c-header-modal--bg {
        opacity: 0;
      }
    }

    &-exit-done {
      & .c-header-modal-box {
        transform: translateX(125%);
      }

      & .c-header-modal--bg {
        opacity: 0;
      }
    }
  }
}
