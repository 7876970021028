.p-health{
    padding-top: 82px;
    @include s-1024 {
      padding-top: 62px;
    }
    @include s-1024 {
      padding-top: 46px;
    }

    .s {
      &-wrap {
        padding-left: 40px;
        padding-right: 40px;
        margin: 0 auto;
        max-width: 2240px;
        @include s-1920 {
          max-width: 1706px;
        }
        @include s-1440 {
          max-width: 1360px;
        }
        @include s-1024 {
          max-width: 100%;
        }
        @include s-640 {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
    .s-hero {
        position: relative;
        height: 1080px;
        @include s-1920 {
          height: 750px;
        }
        @include s-1440 {
          height: 550px;
        }
        @include s-1024 {
          height: 500px;
        }
        @include s-640 {
          height: 510px;
        }
        &-wrap {
          padding-left: 40px;
          padding-right: 40px;
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          z-index: 1;
          max-width: 2240px;
          margin: 0 auto;
          height: 100%;
          @include s-1920 {
            max-width: 1700px;
          }
          @include s-1440 {
            max-width: 1360px;
          }
          @include s-1024 {
            max-width: 100%;
          }
          @include s-640 {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
        &-desc {
            //   flex: 0 0 50%;
            //   max-width: 50%;
            // padding-right: 190px;
          flex: 1;

          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          @include s-768 {
          //   padding-right: 0;
            flex: 0 0 100%;
            max-width: 100%;
          }
          .item {
            max-width: 1080px;
            // @include s-1920 {
            //   max-width: 602px;
            // }
            // @include s-1440 {
            //   max-width: 570px;
            // }
            // @include s-1024 {
            //   max-width: 517px;
            // }
            @include s-768 {
              max-width: 508px;
            }
            @include s-640 {
              max-width: 100%;
            }
            h1 {
              color: var(--color-white);
            }
            &-desc {
              padding-top: 50px;
              max-width: 580px;
              color: var(--color-white);
              font-size: 24px;
              line-height: 145%;
              @include s-1920 {
                max-width: 480px;
                font-size: 20px;
                padding-top: 40px;
              }
              @include s-1440 {
                max-width: 380px;
                font-size: 18px;
                padding-top: 30px;
              }
              @include s-1024 {
                font-size: 14px;
                padding-top: 25px;
              }
              @include s-640 {
                padding-top: 15px;
                max-width: 100%;
              }
            }
            &-link {
              padding-top: 70px;
              max-width: 320px;
              @include s-1920 {
                max-width: 250px;
                padding-top: 50px;
              }
              @include s-1440 {
                max-width: 200px;
              }
              @include s-1024 {
                max-width: 190px;
              }
            }
          }
        }
        &-bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          &:before{
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
              display: none;
              @include s-768{
                display: block;
              }  
            }
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: right;
          }
        }
    }

    .s-care{
        padding-top: 220px;
        padding-bottom: 50px;
    
        @include s-1920{
          padding-top: 200px;
        }
    
        @include s-1440{
          padding-top: 100px;
        }
    
        @include s-1024{
          padding-top: 80px;
        }
        @include s-640{
          padding-top: 50px;
        }
    
        &-wrap{
          display: flex;
          flex-wrap: nowrap;
          padding-left: 40px;
          padding-right: 40px;
          margin: 0 auto;
          max-width: 2240px;
          @include s-1920{
            max-width: 1706px;
          }
          @include s-1440{
            max-width: 1360px;
          }
          @include s-1024{
            max-width: 100%
          }
          @include s-768{
            flex-wrap: wrap;
          }
          @include s-640{
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        &-img{
          flex: 0 0 50%;
          max-width: 50%;
          @include s-768{
            flex: 0 0 100%;
            max-width: 100%;
          }
          img{
            width: 100%;
          }
        }
        &-desc{
          flex: 1;
          display: flex;
          align-items: center;
          padding-left: 220px;
          @include s-1920{
            padding-left: 120px;
          }
          @include s-1024{
            padding-left: 80px;
          }
          @include s-768{
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 0;

          }
          @include s-640{
            padding-left: 15px;
            padding-right: 15px;
          }
          .item{
            @include s-768{
              max-width: 500px;
              margin: 0 auto;
            }
            h2{
              position: relative;
              span{
                display: inline-flex;
                border: 2px solid var(--color-black);
                border-radius: 100%;
                border: 2px solid var(--color-black);
              }
            }
            &-desc{ 
              padding-top: 40px;
              font-size: 24px;
              line-height: 145%;
              @include s-1920{
                font-size: 20px;
                padding-top: 35px;
              }
              @include s-1440{
                font-size: 18px;

              }
              @include s-1024{
                font-size: 14px;
              }
              @include s-640{
                padding-top: 15px;
              }
            }
            &-link{
                padding-top: 80px;
                @include s-1920{
                    padding-top: 70px;
                }
                @include s-1440{
                    padding-top: 60px;
                }
                @include s-1024{
                    padding-top: 20px;
                }
            }
          }
        }
    }

    .s-treat{
        height: 1450px;
        overflow-x: hidden;
        position: relative;
        @include s-1920{
            height: 930px;
        }
        @include s-1440{
            height: 850px;
        }
        @include s-1024{
            // height: 700px;
        }
        &-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
            z-index: 1;
            height: 100%;
            width: 100%;
        }
        &-item{
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }
        &-left{
            flex: 0 0 40%;
            max-width: 860px;
            .item{
                text-align: left;
            }
        }
        &-right{
            flex: 0 0 60%;
            max-width: 1300px;
            .item{
                &-list{
                    display: grid;
                    grid-template-columns: 1.5fr 1.5fr 2fr;
                    @include s-1024{
                        grid-template-columns: 1.5fr  2fr;  
                    }
                    @include s-768{
                        grid-template-columns: 1.5fr 1.5fr 2fr;
                    }
                    @include s-640{
                        grid-template-columns: 1fr 1fr;
                    }
                }
                &-li{
                    // flex: 0 0 33.33%;
                    padding-bottom: 40px;
                    &-item{
                        white-space: nowrap;
                        border-radius: 6px;
                        padding: 20px 25px;
                        display: inline-flex;
                        align-items: center;
                        border: 1px solid var(--color-black);
                    }
                    &-icon{
                        width: 30px;
                        height: 30px;
                        svg{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &-text{
                        padding-left: 20px;
                    }
                }
            }
        }

        &-bg{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            padding-top: 200px;
            padding-bottom: 200px;
            @include s-1920{
            padding-top: 150px;
            padding-bottom: 150px;
            }
            @include s-1024{
            padding-top: 100px;
            padding-bottom: 100px;
            }
            &-grad{
            background: linear-gradient(180deg, #F4F4F4 2.38%, #D9A122 10.72%, #A0971F 49.5%, #608C40 91.54%, #F6F6F6 99.64%);
            filter: blur(50px);
            width: 100%;
            height: 100%;
            transform: scaleX(1.05);
            }
        }
    }

    .s-works{
        padding-top: 220px;
        padding-bottom: 320px;
    
        @include s-1920{
          padding-top: 20px;
          padding-bottom: 250px;
        }
    
        @include s-1440{
          padding-top: 100px;
          padding-bottom: 180px;
        }
    
        @include s-1024{
          padding-top: 80px;
          padding-bottom: 120px;
        }
        @include s-768{
            padding-top: 50px;
            padding-bottom: 150px;
          }
        @include s-640{
          padding-top: 50px;
          padding-bottom: 90px;
        }
    
        &-wrap{
          display: flex;
          flex-wrap: nowrap;
          padding-left: 40px;
          padding-right: 40px;
          margin: 0 auto;
          max-width: 2240px;
          @include s-1920{
            max-width: 1706px;
          }
          @include s-1440{
            max-width: 1360px;
          }
          @include s-1024{
            max-width: 100%
          }
          @include s-768{
            flex-wrap: wrap;
          }
          @include s-640{
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        &-img{
          flex: 0 0 50%;
          max-width: 50%;
          @include s-768{
            flex: 0 0 100%;
            max-width: 100%;
          }
          img{
            width: 100%;
          }
        }
        &-desc{
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: 220px;
          display: flex;
          align-items: center;
          @include s-1920{
            padding-left: 120px;
          }
          @include s-1024{
            padding-left: 80px;
          }
          @include s-768{
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 0;
            padding-top: 60px;
          }
          @include s-640{
            padding-left: 15px;
            padding-right: 15px;
          }
          .item{
            @include s-768{
              max-width: 500px;
              margin: 0 auto;
            }
            h2{
              position: relative;
              span{
                display: inline-flex;
                border: 2px solid var(--color-black);
                border-radius: 100%;
                border: 2px solid var(--color-black);
              }
            }
            &-desc{
              padding-top: 60px;
              font-size: 24px;
              line-height: 145%;
              @include s-1920{
                font-size: 20px;
                padding-top: 35px;
              }
              @include s-1440{
                font-size: 18px;
              }
              @include s-1024{
                font-size: 14px;
              }
              @include s-640{
                padding-top: 15px;
              }
            }
            &-list{
                padding-top: 85px;
                @include s-1920{
                    padding-top: 35px; 
                }
                @include s-1440{ 
                    padding-top: 50px;
                }
                @include s-1024{
                    padding-top: 30px;
                }
                @include s-1024{
                    padding-top: 30px;
                }
            }
            &-li{
                display: flex;
                align-items: center;
                padding-bottom: 40px;
                @include s-1920{
                    padding-bottom: 35px; 
                }
                @include s-1440{ 
                    padding-bottom: 25px;
                }
                @include s-1024{
                    padding-bottom: 20px;
                }
                &-icon{
                    // width: 38px;
                    flex: 0 0 38px;
                    height: 38px;
                    @include s-1920{
                        // width: 32px;
                        flex: 0 0 32px;
                        height: 32px;  
                    }
                    @include s-1440{ 
                        flex: 0 0 24px;
                        // width: 24px;
                        height: 24px;  
                    }
                    @include s-1024{
                        // width: 22px;
                        flex: 0 0 22px;
                        height: 22px;  
                    }
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                &-text{
                    display: block;
                    padding-left: 18px;
                    font-size: 24px;
                    @include s-1920{  
                        font-size: 20px;
                    }
                    @include s-1440{ 
                        font-size: 16px;
                    }
                    @include s-1024{
                        font-size: 14px;  
                    }
                }
            }
          }
        }
    }

    .s-get {
        overflow: hidden;
        &-wrap {
          display: flex;
          @include s-768{
            flex-wrap: wrap;
          }
    
        }
        &-img {
          flex: 0 0 50%;
          max-width: 50%;
          @include s-768 {
            flex: 0 0 100%;
            max-width: 100%;
          }
          img {
            width: 100%;
            object-fit: cover;
            height: 100%;
          }
        }
        &-desc {
          flex: 0 0 50%;
          max-width: 50%;
          position: relative;
          display: flex;
          align-items: center;
          @include s-768 {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 100px;
            padding-bottom: 100px;
          }
          .item {
            position: relative;
            z-index: 1;
            color: var(--color-white);
            padding-left: 220px;
            padding-right: 220px;
            @include s-768{
              max-width: 432px;
              margin:0 auto;
              padding-left: 0;
              padding-right: 0;
            }

            &-title {
              padding-top: 120px;
              @include s-1920{
                padding-top: 70px;
              }
              @include s-1440{
                padding-top: 70px;
              }
              h2 {
                color: var(--color-white);
              }
            }
    
            &-desc {
              padding-top: 20px;
              color: var(--color-grey-2);
              @include s-1024{
                padding-top: 14px;
              }
            }
    
            &-list {
              display: flex;  
              flex-wrap: wrap;
              padding-top: 60px;
              @include s-1024{
                padding-top: 40px;
              }
              li {
                flex: 0 0 50%;
                max-width: 50%;
                padding-left: 35px;
                position: relative;
                padding-bottom: 25px;
                @include s-1920 {
                  padding-left: 20px;
                  padding-right: 30px;
                }
                @include s-1440 {
                  padding-left: 15px;

                }
                @include s-768 {

                }
                @include s-640{
    
                }
                &::before {
                  content: "";
                  width: 15px;
                  height: 15px;
                  background-color: var(--color-green-1);
                  border-radius: 100%;
                  position: absolute;
                  top: 10px;
                  left: 0;
                  display: inline-flex;
                  @include s-1920{
                    width: 10px;
                    height: 10px;
                  }
                  @include s-1440{
                    width: 8px;
                    height: 8px;
                  }
                  @include s-1024{
                    width: 6px;
                    height: 6px;
                  }
                }
              }
              a {
                text-transform: uppercase;
                font-size: 18px;
                line-height: 150%;
                font-family: var(--font-bold-tele);
                color: var(--color-white);
                @include s-1920 {
                  font-size: 16px;
                }
                @include s-1440 {
                  font-size: 14px;
                }
                @include s-1024 {
                  font-size: 11px;
                }
                @include s-640{
    
                }
              }
            }
            &-link {
              padding-top: 120px;
              max-width: 320px;
              @include s-1920 {
                max-width: 250px;
                padding-top: 80px;
              }
              @include s-1440 {
                max-width: 200px;
                padding-top: 70px;
              }
              @include s-1024 {
                max-width: 190px;
                padding-top: 50px;
              }
              @include s-640{
                padding-top: 45px;
              }
            }
          }
          &-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
    }

    .s-faqs{
        padding-top: 295px;
        padding-bottom: 250px;
    }

}