.c {
  &-wrap {
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    margin: 0 auto;
    max-width: 2240px;
    @include s-1920 {
      max-width: 1706px;
    }
    @include s-1440 {
      max-width: 1360px;
    }
    @include s-1024 {
      max-width: 100%;

    }
    @include s-768 {
      flex-wrap: wrap;
      
    }
    @include s-640 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  &-faqs {
    &-left {
      flex: 0 0 50%;
      max-width: 50%;
      @include s-768{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &-list {
      flex: 1;
      @include s-768{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &--title {
      h2 {
        position: relative;
        span {
          display: inline-flex;
          border: 2px solid var(--color-black);
          border-radius: 100%;
          border: 2px solid var(--color-black);
        }
      }
    }
    &-link {
      padding-top: 150px;
      .btn-arrow{
        max-width: 300px;
      }
    }
  }
  &-faq {
    &-item {
      border-top: 1px solid var(--color-grey-2);
      padding: 0;
      transition: padding 0.4s ease-in-out;

      &:first-of-type {
        border-top: none;
      }

      &.active {
        padding: 0 0 30px 0;

        & .c-faq-item-wrap::after {
          transform: rotate(180deg);
        }

        & .c-faq-item-block {
          height: auto;
          max-height: 9999px;
        }
      }

      &-wrap {
        position: relative;
        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 40px;
          right: 7px;
          width: 28px;
          height: 2px;
          background-color: var(--color-black);

          @include s-1920{
            width: 21px;
          }
          @include s-1440{
            width: 16px;
          } 
          @include s-1024{
            width: 15px;
          }
          @include s-640{
            width: 14px;
          }
        }
        &:after {
          transform: rotate(90deg);
          transition: transform  0.4s;
        }
      }
      &-title {
        font-size: 34px;
        line-height: 140%;
        padding: 30px 50px 30px 0;
        position: relative;
        @include s-1920{
          font-size: 24px;
          padding: 25px 50px 25px 0;
        }
        @include s-1440{
          font-size: 20px;
        }
        @include s-1024{
          font-size: 18px;
        }
        @include s-768{
          
        }
        @include s-640{
          font-size: 16px;
        }
        span{
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-block {
        max-height: 0;
        transition: all ease 0.4s;
        overflow: hidden;
        font-size: 18px;
        line-height: 24px;
        padding: 0 50px 0 0;
        max-width: 1050px;
      }
    }
  }

}
