:root {
  //Grayscale
  --color-white: #ffffff;
  --color-white-1: #fbfbfb;
  --color-white-2: #f2f2f2;

  --color-grey: #4C4E4D;
  --color-grey-1: #262626;
  --color-grey-2: #8F8F8F;
  --color-grey-3: #C2C2C2;

  --color-black: #000403;
  --color-black-1: #222125;
  --color-black-2: #131215;

  // Categories
  --color-categories: #5cb1ff;
  --color-categories-1: #16c0c2;
  --color-categories-2: #bb9bff;
  --color-categories-3: #ff985f;
  --color-categories-4: #1270ce;
  --color-categories-5: #0e4747;
  --color-categories-6: #5123f5;
  --color-categories-7: #d24e19;
  --color-categories-8: #6d28ff;
  --color-categories-9: #14a1a3;
  --color-categories-10: #b9e3e3;
  --color-categories-11: #828284;

  --color-green: #2F7E62;
  --color-green-1: #969913;
  --color-orange: #F9A01A;
  --color-red: #D75437;
}
