:root {
  // font Poppins family
  // --font-regular: "poppins-regular", sans-serif;
  // --font-medium: "poppins-medium", sans-serif;
  // --font-semibold: "poppins-semibold", sans-serif;

  --font-regular: "HelveticaNowTextRegular", sans-serif;
  --font-light: "HelveticaNowTextLight", sans-serif;
  --font-bold: "HelveticaNowTextBold", sans-serif;
  --font-regular-tele: "PPTelegraf-Regular", sans-serif;
  --font-bold-tele: "PPTelegraf-Bold", sans-serif;
}
