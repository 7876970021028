.p-blog {
  padding-top: 82px;
  @include s-1024 {
    padding-top: 62px;
  }
  @include s-1024 {
    padding-top: 46px;
  }
  .s-hero {
    position: relative;
    height: 820px;
    @include s-1920 {
      height: 550px;
    }
    @include s-1440 {
      height: 480px;
    }
    @include s-1024 {
      height: 390px;
    }
    @include s-640 {
      height: 260px;
    }
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      z-index: 1;
      max-width: 2240px;
      margin: 0 auto;
      height: 100%;
      @include s-1920 {
        max-width: 1700px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-1024 {
        max-width: 100%;
      }
      @include s-640 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    &-desc {
      flex: 0 0 50%;
      max-width: 50%;
      flex: 1;
      padding-right: 190px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include s-768 {
        padding-right: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item {
        max-width: 1080px;
        @include s-1920 {
          max-width: 602px;
        }
        @include s-1440 {
          max-width: 570px;
        }
        @include s-1024 {
          max-width: 517px;
        }
        @include s-768 {
          max-width: 508px;
        }
        @include s-640 {
          max-width: 100%;
        }
        h1 {
          color: var(--color-white);
        }
        &-desc {
          padding-top: 50px;
          max-width: 430px;
          color: var(--color-white);
          font-size: 24px;
          line-height: 145%;
          @include s-1920 {
            max-width: 410px;
            font-size: 20px;
            padding-top: 40px;
          }
          @include s-1440 {
            max-width: 380px;
            font-size: 18px;
            padding-top: 30px;
          }
          @include s-1024 {
            font-size: 14px;
            padding-top: 25px;
          }
          @include s-640 {
            padding-top: 15px;
            max-width: 100%;
          }
        }
      }
    }
    &-img {
      flex: 0 0 50%;
      max-width: 50%;
    }
    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .s-posts {
    padding-top: 200px;
    padding-bottom: 320px;
    @include s-1920 {
      padding-top: 150px;
    }
    @include s-1440 {
      padding-top: 100px;
    }
    @include s-1024 {
      padding-top: 80px;
    }
    @include s-640 {
      padding-top: 60px;
    }
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      max-width: 2260px;
      margin: 0 auto;
      @include s-1920 {
        max-width: 1700px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-640 {
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    &-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include s-640 {
        flex-wrap: wrap;
      }
      &--title {
        font-family: var(--font-regular-tele);
        font-size: 74px;
        line-height: 140%;
        padding-right: 20px;
        @include s-1920 {
          font-size: 58px;
        }
        @include s-1440 {
          font-size: 46px;
        }
        @include s-1024 {
          font-size: 36px;
        }
        @include s-640 {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &-ul {
        display: flex;
        flex-wrap: wrap;
        @include s-640 {
          flex: 0 0 100%;
          max-width: 100%;
          padding-top: 13px;
        }
      }
      &-li {
        padding-right: 10px;
        padding-bottom: 10px;
        @include s-640 {
          padding-right: 8px;
          padding-bottom: 8px;
        }
        &:last-of-type {
          padding-right: 0;
        }
        span {
          padding: 8px 25px 6px;
          border: 1px solid var(--color-black);
          border-radius: 20px;
          font-family: var(--font-bold-tele);
          font-size: 16px;
          text-transform: uppercase;
          display: block;
          color: var(--color-black);
          white-space: nowrap;
          letter-spacing: 0.05em;
          cursor: pointer;

          @include s-1440 {
            font-size: 12px;
            padding: 6px 15px 4px;
          }

          @include s-640 {
            padding: 6px 13px 4px;
          }

          &:hover,
          &.active {
            border: 1px solid var(--color-black);
            background-color: var(--color-black);
            color: var(--color-white);
          }
        }
      }
    }
    &-list {
      padding-top: 65px;
      @include s-1440 {
        padding-top: 60px;
      }
      @include s-1024 {
        padding-top: 35px;
      }
      @include s-640 {
        padding-top: 50px;
      }
      & > div {
        .pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          &-li {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include s-1920 {
              height: 30px;
              width: 30px;
            }
             &.active {
              & span {
                color: var(--color-black);
              }
            }
            
            & span {
              display: block;
              width: 100%;
              height: 100%;
              color: var(--color-grey-2);
              font-size: 24px;
              font-family: var(--font-bold);
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              @include s-1920 {
                font-size: 20px;
              }
              @include s-1440 {
                font-size: 18px;
              }
              @include s-1024 {
                font-size: 16px;
              }
              @include s-640 {
                padding-top: 50px;
              }
              &.active {
                color: var(--color-black);
              }

              &.disabled {
                pointer-events: none;
                opacity: 0.3;
              }
            }
          }

          &-arrow{
            width: 38px;
            height: 38px;
            cursor: pointer;
            
            &-prev{
              margin-right: 60px;
              @include s-1920{
                margin-right: 50px;
              }
              @include s-1440{
                margin-right: 40px;
              }
            }
            &-next{
              margin-left: 60px;
              @include s-1920{
                margin-left: 50px;
              }
              @include s-1440{
                margin-left: 40px;
              }
            }
            &.disabled{
              cursor: default;
              opacity: 0.3;
            }
          }
        }
      }
      &-ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -25px;
        @include s-1440 {
          margin: 0 -14px;
        }
        @include s-1024 {
          margin: 0 -17px;
        }
        @include s-768 {
          margin: 0 -19px;
        }
        @include s-640 {
          margin: 0;
        }
      }
      &-li {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 80px;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        @include s-1920 {
          padding-bottom: 70px;
        }
        @include s-1440 {
          padding-left: 14px;
          padding-right: 14px;
          padding-bottom: 60px;
        }
        @include s-1024 {
          padding-left: 17px;
          padding-right: 17px;
          padding-bottom: 40px;
        }
        @include s-768 {
          flex: 0 0 50%;
          max-width: 50%;
          padding-bottom: 40px;
          padding-left: 19px;
          padding-right: 19px;
        }
        @include s-640 {
          flex: 0 0 100%;
          max-width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }
      .item {
        &-img {
          width: 100%;
          height: 445px;
          border-radius: 6px;
          overflow: hidden;
          @include s-1920 {
            height: 330px;
          }
          @include s-1440 {
            height: 265px;
          }
          @include s-1024 {
            height: 190px;
          }
          @include s-768 {
            height: 212px;
          }
          @include s-640 {
            height: 206px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &-cat {
          padding-top: 20px;
          display: flex;
          @include s-1920 {
            padding-top: 15px;
          }
          @include s-1440 {
            padding-top: 12px;
          }
          &-title {
            margin-left: 10px;
            padding: 8px 25px 6px;
            border: 1px solid var(--color-black);
            border-radius: 20px;
            font-family: var(--font-bold-tele);
            font-size: 16px;
            text-transform: uppercase;
            line-height: 130%;
            @include s-1440 {
              font-size: 12px;
              padding: 8px 15px 5px;
              line-height: 100%;
            }
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
        &-title {
          padding-top: 20px;
          @include s-1920 {
            padding-top: 15px;
          }
          @include s-1440 {
            padding-top: 12px;
          }
          h5 {
            font-family: var(--font-regular-tele);
            font-size: 42px;
            line-height: 140%;
            font-size: calcFluidFontSize(34, 42, 1920, 2560);
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            @include s-1920 {
              font-size: calcFluidFontSize(28, 34, 1440, 1920);
            }

            @include s-1440 {
              font-size: calcFluidFontSize(24, 28, 1024, 1440);
            }

            @include s-768 {
              font-size: calcFluidFontSize(20, 24, 640, 768);
            }

            @include s-640 {
              font-size: 20px;
            }
          }
        }
        &-desc {
          padding-top: 20px;
          font-size: 20px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          @include s-1440 {
            padding-top: 12px;
            font-size: 16px;
          }
          @include s-1024 {
            padding-top: 10px;
            font-size: 14px;
          }
        }
        &-link {
          padding-top: 20px;
          @include s-1440 {
            padding-top: 15px;
          }
          @include s-640 {
            padding-top: 18px;
          }
          .link-grad-arrow {
            font-family: var(--font-bold-tele);
          }
        }
      }
    }
  }
}
.p-post {
  padding-top: 82px;
  @include s-1024 {
    padding-top: 62px;
  }
  @include s-1024 {
    padding-top: 46px;
  }
  .s-hero {
    position: relative;
    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 0;
      height: 1000px;
      @include s-1920 {
        height: 750px;
      }
      @include s-1440 {
        height: 560px;
      }
      @include s-1024 {
        height: 500px;
      }
      @include s-640 {
        height: 510px;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      position: relative;
      z-index: 1;
      max-width: 2240px;
      margin: 0 auto;
      height: 100%;
      z-index: 1;
      @include s-1920 {
        max-width: 1700px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-1024 {
        max-width: 100%;
      }
      @include s-640 {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &-back{
      padding-top: 60px;
      @include s-1920{
        padding-top: 50px;
      }
      @include s-1440{
        padding-top: 40px;
      }
      @include s-640{
        padding-top: 30px;
      }
      a{
        display: flex;
        align-items: center;
        color: var(--color-grey-2);
        font-size: 20px;
        font-family: var(--font-bold);
        @include s-1920{
          font-size: 18px;
        }
        @include s-1024{
          font-size: 14px;
        }
      }
      .icon{
        width: 20px;
        height: 20px;
        display: block;
        svg{
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .text{
        padding-left: 12px;
        display: block;
        // height: 20px;
        line-height: 1;

      }
    }
    &-img {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      height: 907px;
      @include s-1920 {
        height: 680px;
      }
      @include s-1440 {
        height: 540px;
      }
      @include s-1024 {
        height: 400px;
      }
      @include s-768 {
        height: 290px;
      }
      @include s-640 {
        height: 345px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-top {
      padding-top: 220px;
      color: var(--color-white);
      @include s-1920 {
        padding-top: 150px;

      }
      @include s-1440 {
        padding-top: 100px;

      }
      @include s-1024 {
        padding-top: 90px;

      }
      @include s-768 {
        padding-top: 70px;
      }
      @include s-640 {
        padding-top: 65px;
      }
    }
    &-middle {
      padding-top: 70px;
      @include s-1920 {
        padding-top: 60px;

      }
      @include s-1440 {
        padding-top: 55px;

      }
      @include s-1024 {
        padding-top: 35px;

      }
      @include s-640 {
        padding-top: 25px;

      }
    }
    &-option{
      .info{
        font-family: var(--font-bold); 
        padding-top: 12px;
        font-size: 26px;
        @include s-1920{
          font-size: 20px;
        }
        @include s-1440{
          font-size: 18px;
        }
        @include s-1024{
          font-size: 16px;
        }
        @include s-1024{
          font-size: 14px;
        }
        &:first-of-type{
          padding-top: 0;
        }   

      }
      .label{
        padding-right: 10px;

      }
      .value{

      }
    }
    &-bottom {
      padding-top: 150px;
      @include s-1920{
        padding-top: 120px;
      }
      @include s-1440{
        padding-top: 100px;
      }
      @include s-1024{
        padding-top: 60px;
      }
      @include s-640{
        padding-top: 50px;
      }
      &-wrap {
        max-width: 1030px;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
        @include s-1920{
          max-width: 840px;
        }
        @include s-1440{
          max-width: 680px;
        }
        @include s-768{
          max-width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    &-up{
      display: flex;
      justify-content: center;
      padding-top: 130px;
      @include s-1920{
        padding-top: 100px;
      }
      @include s-1440{
        padding-top: 80px;
      }
      @include s-768{
        padding-top: 65px;
      }
      @include s-640{
        padding-top: 50px;
      }
      &-btns{
        .btn-up{
          width: 48px;
          height: 48px;
          svg{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .s-posts {
    padding-top: 200px;
    padding-bottom: 270px;
    @include s-1920 {
      padding-top: 150px;
      padding-bottom: 150px;
    }
    @include s-1440 {
      padding-top: 100px;
      padding-bottom: 110px;
    }
    @include s-1024 {
      padding-top: 80px;
      padding-bottom: 70px;
    }
    @include s-768 {
      padding-bottom: 60px;
    }
    @include s-640 {
      padding-top: 60px;
      padding-bottom: 80px;
    }
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      max-width: 2260px;
      margin: 0 auto;
      @include s-1920 {
        max-width: 1700px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-640 {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &-list {
      padding-top: 65px;
      @include s-1440 {
        padding-top: 60px;
      }
      @include s-1024 {
        padding-top: 35px;
      }
      @include s-768 {
        padding-top: 20px;
      }
      &-ul {
        display: flex;
        flex-wrap: no-wrap;
        margin: 0 -25px;
        @include s-1440 {
          margin: 0 -14px;
        }
        @include s-1024 {
          margin: 0 -17px;
        }
        @include s-768 {
          margin: 0 -9px;
          overflow: auto;
        }
        @include s-640 {
          margin: 0 -7px;
        }
      }
      &-li {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 80px;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        @include s-1920 {
          padding-bottom: 70px;
        }
        @include s-1440 {
          padding-left: 14px;
          padding-right: 14px;
          padding-bottom: 60px;
        }
        @include s-1024 {
          padding-left: 17px;
          padding-right: 17px;
          padding-bottom: 40px;
        }
        @include s-768 {
          // flex: 0 0 50%;
          // max-width: 50%;
          padding-bottom: 40px;
          padding-left: 9px;
          padding-right: 9px;
          flex: 0 0 344px;
          max-width: 344px;
        }
        @include s-640 {
          flex: 0 0 344px;
          max-width: 344px;
          padding-left: 7px;
          padding-right: 7px;
         }
      }
      .item {
        &-img {
          width: 100%;
          height: 445px;
          border-radius: 6px;
          overflow: hidden;
          @include s-1920 {
            height: 330px;
          }
          @include s-1440 {
            height: 265px;
          }
          @include s-1024 {
            height: 190px;
          }
          @include s-768 {
            height: 212px;
          }
          @include s-640 {
            height: 206px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &-cat {
          padding-top: 20px;
          display: flex;
          @include s-1920 {
            padding-top: 15px;
          }
          @include s-1440 {
            padding-top: 12px;
          }
          &-title {
            margin-left: 10px;
            padding: 8px 25px 6px;
            border: 1px solid var(--color-black);
            border-radius: 20px;
            font-family: var(--font-bold-tele);
            font-size: 16px;
            text-transform: uppercase;
            line-height: 130%;
            @include s-1440 {
              font-size: 12px;
              padding: 8px 15px 5px;
              line-height: 100%;
            }
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
        &-title {
          padding-top: 20px;
          @include s-1920 {
            padding-top: 15px;
          }
          @include s-1440 {
            padding-top: 12px;
          }
          h5 {
            font-family: var(--font-regular-tele);
            font-size: 42px;
            line-height: 140%;
            font-size: calcFluidFontSize(34, 42, 1920, 2560);
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            @include s-1920 {
              font-size: calcFluidFontSize(28, 34, 1440, 1920);
            }

            @include s-1440 {
              font-size: calcFluidFontSize(24, 28, 1024, 1440);
            }

            @include s-768 {
              font-size: calcFluidFontSize(20, 24, 640, 768);
            }

            @include s-640 {
              font-size: 20px;
            }
          }
        }
        &-desc {
          padding-top: 20px;
          font-size: 20px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          @include s-1440 {
            padding-top: 12px;
            font-size: 16px;
          }
          @include s-1024 {
            padding-top: 10px;
            font-size: 14px;
          }
        }
        &-link {
          padding-top: 20px;
          @include s-1440 {
            padding-top: 15px;
          }
          @include s-640 {
            padding-top: 18px;
          }
          .link-grad-arrow {
            font-family: var(--font-bold-tele);
          }
        }
      }
    }
  }
}
