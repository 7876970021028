body {
  background-color: var(--color-white);
  color: var(--color-black);
  font-family: var(--font-regular);
  font-size: calcFluidFontSize(20, 26, 1920, 2560);
  line-height: 125%;

  @include s-1920 {
    font-size: calcFluidFontSize(16, 20, 1440, 1920);
  }

  @include s-1440 {
    font-size: 16px;
  }

  &.modal-open {
    overflow: hidden;
  }
}

html {
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}