.c-start {
  &-box {
    background: linear-gradient(84.42deg, #2f7e62 -9.76%, #969913 66.62%, #f9a01a 117.96%);
    border-radius: 38px;
    text-align: center;
    padding: 210px 50px 170px;
    @include s-1920 {
      padding: 140px 50px 125px;
    }
    @include s-1440 {
      padding: 130px 50px 114px;
    }
    @include s-1024 {
      padding: 130px 50px 114px;
    }
    @include s-768 {
    }
    @include s-640 {
    }
    h2 {
      font-family: var(--font-regular-tele);
      color: var(--color-white);
    }
  }
  &-link {
    margin-top: 62px;
  }
}