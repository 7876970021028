.c-footer {
  background-color: var(--color-black);
  color: var(--color-grey);
  margin: auto 0 0 0;

  &-wrap {
    margin: 0 auto;
    max-width: 2220px;
    padding-left: 40px;
    padding-right: 40px;
    @include s-1920 {
      max-width: 1700px;
    }
    @include s-1440 {
      max-width: 1360px;
    }
    @include s-1024 {
      max-width: 100%;
    }
    @include s-768 {
    }
    @include s-640 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  &-top {
    padding-top: 80px;
    padding-bottom: 66px;
    @include s-1920 {
      padding-bottom: 70px;
    }
    @include s-1440 {
    }
    @include s-1024 {
      padding-bottom: 35px;
    }
    @include s-768 {
    }
    @include s-640 {
      padding-bottom: 30px;
    }
    color: var(--color-grey-2);
    &-wrap {
      display: flex;
      justify-content: space-between;
      @include s-640 {
        flex-wrap: wrap;
      }
    }
    &-left {
      flex: 0 0 28%;
      max-width: 610px;
      padding-right: 30px;
      @include s-1920 {
        flex: 0 0 22.5%;
      }
      @include s-1440 {
        flex: 0 0 18%;
      }
      @include s-1024 {
        flex: 0 0 26%;
      }
      @include s-768 {
        flex: 0 0 21%;
      }
      @include s-640 {
        max-width: 50%;
        flex: 0 0 50%;
      }
      .c-footer-top-menu {
        &--list {
          display: block !important;
        }
        &--item {
          font-size: 20px;
          @include s-1920 {
            font-size: 18px;
          }
          @include s-1440 {
            font-size: 16px;
          }
          @include s-1024 {
            font-size: 14px;
          }
        }
      }
      .social {
        padding-top: 25px;
        display: flex;
        &--item {
          padding-right: 15px;
          @include s-1440 {
            padding-right: 10px;
          }
          &:last-of-type {
            padding-right: 0;
          }
          a {
            width: 38px;
            height: 38px;
            display: block;
            @include s-1920 {
              width: 32px;
              height: 32px;
            }
            @include s-1440 {
              width: 24px;
              height: 24px;
            }
            @include s-640 {
              width: 28px;
              height: 28 px;
            }
            svg {
              width: 100%;
              height: 100%;
              path {
                fill: var(--color-grey-2);
              }
            }
            &:hover {
              svg {
                path {
                  fill: var(--color-white);
                }
              }
            }
          }
        }
      }
    }
    &-menu {
      @include s-640 {
        max-width: 50%;
        flex: 0 0 50%;
        padding-bottom: 30px;
      }
      &[data-menu="0"] {
        display: none;
      }
      &[data-menu="3"] {
        .c-footer-top-menu--list {
          padding-right: 0;
        }
      }
      &--list {
        padding-right: 30px;
        max-width: 215px;
        @include s-1920 {
          max-width: 195px;
        }
        @include s-1024 {
          max-width: 156px;
        }
        @include s-640 {
          max-width: 100%;
          padding-right: 0;
        }
      }
      &--item {
        padding-bottom: 8px;
        line-height: 145%;
        @include s-1920 {
          padding-bottom: 7px;
        }
        @include s-1440 {
          padding-bottom: 5px;
        }
        &:last-of-type {
          padding-bottom: 0;
        }
        a {
          font-size: 20px;
          @include s-1920 {
            font-size: 18px;
          }
          @include s-1440 {
            font-size: 16px;
          }
          @include s-1024 {
            font-size: 14px;
          }
          &:hover {
            color: var(--color-white);
          }
        }
      }
    }
    &-partners {
      flex: 0 0 28%;
      max-width: 610px;
      padding-left: 30px;
      @include s-1920 {
        flex: 0 0 22.5%;
      }
      @include s-1440 {
        flex: 0 0 18%;
      }
      @include s-1024 {
        flex: 0 0 26%;
      }
      @include s-768 {
        flex: 0 0 21%;
      }
      @include s-640 {
        max-width: 100%;
        flex: 0 0 100%;
        padding-left: 0;
      }
      &--list {
        @include s-640 {
          display: flex;
          justify-content: space-between;
        }
      }
      &--item {
        display: flex;
        // width: 100%;
        justify-content: flex-end;
        padding-bottom: 25px;

        @include s-640 {
          padding-bottom: 0;
          padding-left: 20px;
          padding-right: 20px;
        }
        &:first-of-type {
          @include s-640 {
            padding-left: 0;
          }
        }
        &:last-of-type {
          padding-bottom: 0;
          @include s-640 {
            padding-right: 0;
          }
        }
      }
      a {
        // display: block;
        // width: 100%;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  &-bottom {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid var(--color-grey-1);
    @include s-1920 {
      padding-top: 37px;
      padding-bottom: 37px;
    }
    @include s-1440 {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @include s-1024 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @include s-640 {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
      @include s-640 {
        flex-wrap: wrap;
      }
      .publish {
        line-height: 145%;
        font-size: 20px;
        @include s-1920 {
          font-size: 18px;
        }
        @include s-1440 {
          font-size: 16px;
        }
        @include s-1024 {
          font-size: 14px;
        }
        @include s-640 {
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center;
          padding-bottom: 20px;
        }
      }
      .development {
        @include s-640 {
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center;
        }
        &-link {
          font-family: var(--font-bold);
          color: var(--color-grey-2);
          line-height: 145%;
          line-height: 145%;
          font-size: 20px;
          @include s-1920 {
            font-size: 18px;
          }
          @include s-1440 {
            font-size: 16px;
          }
          @include s-1024 {
            font-size: 14px;
          }

          svg {
            margin-right: 10px;
            width: 32px;
            height: 17px;
            path {
              fill: var(--color-grey-2);
            }
            @include s-1920 {
              width: 28px;
              height: 14px;
            }
            @include s-1440 {
              width: 24px;
              height: 12px;
            }
            @include s-1024 {
              width: 19px;
              height: 10px;
            }
          }
          &:hover {
            color: var(--color-white);
            svg {
              path {
                fill: var(--color-white);
              }
            }
          }
        }
      }
    }
  }
}
