.p-404{
    padding-top: 82px;
    @include s-1024{
      padding-top: 62px;
    }
    @include s-1024{
      padding-top: 46px;
    }
    .s{
        &-wrap{
          padding-left: 40px;
          padding-right: 40px;
          margin: 0 auto;
          max-width: 2240px;
          @include s-1920{
            max-width: 1706px;
          }
          @include s-1440{
            max-width: 1360px;
          }
          @include s-1024{
            max-width: 100%
          }
          @include s-640{
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
      .s-hero{ 
        position: relative;
        padding-top: 100px;
        padding-bottom: 180px;
        @include s-1920{
          padding-top: 80px;
          padding-bottom: 110px;
        }
        @include s-1024{
          padding-top: 90px;
          padding-bottom: 140px;
        }
        @include s-640{
          padding-top: 70px;
          padding-bottom: 70px;
        }
        &-wrap{
          padding-left: 40px;
          padding-right: 40px;
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          z-index: 1;
          max-width: 2240px;
          margin: 0 auto;
          height: 100%;
          @include s-1920{
            max-width: 1700px;
          }
          @include s-1440{
            max-width: 1360px;
          }
          @include s-1024{
            max-width: 100%;
          }
          @include s-768{
            flex-wrap: wrap;
          } 
          @include s-640{
            padding-left: 15px;     
            padding-right: 15px;
          }
    
        }
        &-desc{
          flex: 0 0 50%;
          max-width: 50%;
          padding-right: 60px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          @include s-768{
            padding-right: 0;
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
            justify-content: center;
          }
          .item{
            h1{
              background: linear-gradient(90deg, #2f7e62 -34.83%, #969913 48.44%, #f9a01a 137.64%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            &-desc{
              padding-top: 80px; 
              @include s-1920{
                padding-top: 50px;
              }
              @include s-1440{
                padding-top: 40px;
              }
              @include s-1024{
                padding-top: 30px;
              }
              @include s-768{
                padding-top: 20px;
              }
              @include s-640{
                padding-top: 15px; 
              }
              h4{
                font-size: 64px;
                @include s-1920{
                  font-size: 48px;
                }
                @include s-1440{
                  font-size: 38px;
                }
                @include s-1024{
                  font-size: 32px;
                }
                @include s-768{
                  
                }
                @include s-640{
                  
                }
              }
            }
            &-link{
                padding-top: 100px;
                @include s-1920{
                    padding-top: 70px;
                }
                @include s-1440{
                    padding-top: 50px;
                }
                @include s-1024{
                    padding-top: 40px;
                }
                @include s-640{
                    padding-top: 30px;
                }
                @include s-640{
                    padding-top: 25px;
                }
            }
          }
    
        }
        &-img{
          flex: 0 0 50%;
          max-width: 50%;
          @include s-768{
            padding-top: 90px;
            flex: 0 0 100%;
            max-width: 100%;
          }
          @include s-640{
            padding-top: 60px;
          }
          .item{
            &-img{
                width: 100%;
                overflow: hidden;
                border-radius: 10px;
                img{
                    width: 100%;
                }
            }
          }
        }
    
      }
}