@font-face {
  font-family: "poppins-regular";
  src: url("../fonts/Poppins-Regular.eot");
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins-medium";
  src: url("../fonts/Poppins-Medium.eot");
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff"),
    url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins-semibold";
  src: url("../fonts/Poppins-SemiBold.eot");
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
    url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowTextRegular';
  src: url('../fonts/HelveticaNowText-Regular.eot');
  src: local('HelveticaNowText Regular'), local('HelveticaNowText-Regular'),
      url('../fonts/HelveticaNowText-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/HelveticaNowText-Regular.woff2') format('woff2'),
      url('../fonts/HelveticaNowText-Regular.woff') format('woff'),
      url('../fonts/HelveticaNowText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowTextLight';
  src: url('../fonts/HelveticaNowText-Light.eot');
  src: local('HelveticaNowText Light'), local('HelveticaNowText-Light'),
      url('../fonts/HelveticaNowText-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/HelveticaNowText-Light.woff2') format('woff2'),
      url('../fonts/HelveticaNowText-Light.woff') format('woff'),
      url('../fonts/HelveticaNowText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'HelveticaNowTextBold';
  src: url('../fonts/HelveticaNowText-Bold.eot');
  src: local('HelveticaNowText Bold'), local('HelveticaNowText-Bold'),
      url('../fonts/HelveticaNowText-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/HelveticaNowText-Bold.woff2') format('woff2'),
      url('../fonts/HelveticaNowText-Bold.woff') format('woff'),
      url('../fonts/HelveticaNowText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PPTelegraf-Regular';
  src: url('../fonts/PPTelegraf-Regular.eot');
  src: local('PPTelegraf-Regular'), local('PPTelegraf-Regular'),
      url('../fonts/PPTelegraf-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/PPTelegraf-Regular.woff2') format('woff2'),
      url('../fonts/PPTelegraf-Regular.woff') format('woff'),
      url('../fonts/PPTelegraf-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PPTelegraf-Bold';
  src: url('../fonts/PPTelegraf-Bold.eot');
  src: local('PPTelegraf Bold'), local('PPTelegraf-Bold'),
      url('../fonts/PPTelegraf-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/PPTelegraf-Bold.woff2') format('woff2'),
      url('../fonts/PPTelegraf-Bold.woff') format('woff'),
      url('../fonts/PPTelegraf-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}