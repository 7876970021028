.p-article {
  color: var(--color-black-2);
  background-color: var(--color-white-2);

  & img {
    width: 100%;
    height: auto;
  }

  &-box {
    padding: 400px 0 250px 0;

    @include s-1920 {
      padding: 350px 0 150px 0;
    }

    @include s-1440 {
      padding: 220px 0 130px 0;
    }

    @include s-640 {
      padding: 150px 0 120px 0;
    }
  }

  &-top {
    max-width: 1620px;
    padding: 0 20px;
    margin: 0 auto;

    @include s-1920 {
      max-width: 1220px;
    }

    @include s-1440 {
      max-width: 1020px;
    }

    @include s-1024 {
      max-width: 100%;
    }

    &--category {
      color: var(--color-categories-4);
      text-transform: uppercase;
    }

    & h2 {
      padding-top: 80px;

      @include s-1920 {
        padding-top: 60px;
      }

      @include s-1440 {
        padding-top: 40px;
      }

      @include s-640 {
        padding-top: 25px;
      }
    }

    &-info {
      padding-top: 120px;

      @include s-1920 {
        padding-top: 80px;
      }

      @include s-1440 {
        padding-top: 60px;
      }

      @include s-640 {
        padding-top: 40px;
      }

      & .el-title--h7 {
        display: block;
        text-transform: uppercase;
        letter-spacing: 0.02em;

        &.date {
          padding-top: 30px;

          @include s-1440 {
            padding-top: 20px;
          }

          @include s-640 {
            padding-top: 20px;
          }
        }

        & .time {
          position: relative;

          &:before {
            position: relative;
            content: "";
            display: inline-block;
            border: solid 3px var(--color-categories);
            vertical-align: middle;
            transform: rotate(45deg);
            margin: 0 10px;
          }
        }
      }
    }
  }

  &-img {
    margin-top: 120px;

    @include s-1920 {
      margin-top: 80px;
    }

    @include s-1440 {
      margin-top: 60px;
    }

    @include s-640 {
      margin-top: 40px;
    }

    &--author {
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: var(--color-grey-1);
      margin: 0 auto;
      display: block;
      padding-top: 10px;

      @include s-1920 {
        font-size: 16px;
      }

      @include s-1440 {
        font-size: 14px;
        padding-top: 7px;
      }
      @include s-640 {
        padding-top: 5px;
      }
    }
  }

  &-image {
    margin-top: 60px;

    @include s-1920 {
      margin-top: 50px;
    }

    @include s-1440 {
      margin-top: 40px;
    }

    @include s-640 {
      margin-top: 25px;
    }
  }

  &-author {
    padding-top: 120px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    @include s-1920 {
      padding-top: 80px;
    }

    @include s-1440 {
      padding-top: 60px;
    }

    &--img {
      width: 100px;
      height: 100px;
      margin-right: 20px;
      position: relative;
      z-index: 1;

      @include s-1920 {
        width: 90px;
        height: 90px;
        margin-right: 15px;
      }

      @include s-1440 {
        width: 72px;
        height: 72px;
      }

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: 50%;
        background: linear-gradient(to right bottom, #a377ff, #30c9cb);
      }

      & img {
        margin-top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &-desc {
      & span {
        margin-top: 10px;
        display: block;
      }

      & ul {
        padding-top: 15px;
        display: flex;
      }

      & svg {
        width: 20px;
        height: 20px;
        margin-right: 12px;
        fill: var(--color-categories-11);
        transition: fill ease-out 0.2s;

        @include s-1440 {
          width: 15px;
          height: 15 px;
        }

        &:hover {
          fill: var(--color-grey-2);
        }
      }
    }
  }

  &-mark {
    padding: 40px;
    background-color: var(--color-white);
    margin-top: 120px;
    font-size: 24px;
    line-height: 180%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include s-1920 {
      font-size: 20px;
      margin-top: 80px;
    }

    @include s-1440 {
      font-size: 18px;
      margin-top: 60px;
    }

    @include s-640 {
      flex-wrap: wrap;
      padding: 15px;
    }

    &-like {
      @include s-640 {
        width: 100%;
      }

      & .like {
        margin-left: 30px;

        @include s-1440 {
          margin-left: 15px;
        }

        &-count {
          margin: 0 10px;
          display:inline-block;
          color: var(--color-grey-1);

          @include s-1440 {
            margin: 0 7px;
          }
        }
      }
    }

    &-share {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include s-640 {
        padding-top: 40px;
      }

      & ul {
        margin-left: 30px;

        @include s-1440 {
          margin-left: 20px;
        }

        @include s-640 {
          margin-left: 15px;
        }
      }

      & li {
        display: inline-flex;
        margin-right: 15px;
        position: relative;
        z-index: 1;

        &:before {
          content: '';
          position: absolute;
          border-radius: 50%;
          z-index: -1;
          left: 50%;
          top: 50%;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          transform: translate(-50%, -50%);
          background: linear-gradient(to right bottom, #a377ff, #30c9cb);
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      & a {
        overflow: hidden;
        border-radius: 50%;
        padding: 12px;
        display: inline-flex;
        background-color: var(--color-white);
        position: relative;
        z-index: 1;

        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          left: 50%;
          top: 50%;
          transform: translate(-150%, -50%);
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          border-radius: 50%;
          background: linear-gradient(to right bottom, #a377ff, #30c9cb);
          transition: all ease-out .2s;
        }

        &:hover {
          &:after {
            transform: translate(-50%, -50%);
          }
        }
      }

      & svg {
        width: 20px;
        height: 20px;

        @include s-1440 {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &-also {
    background-color: var(--color-black-2);
    color: var(--color-white);
    padding-top: 250px;

    @include s-1920 {
      padding-top: 150px;
    }

    @include s-1440 {
      padding-top: 130px;
    }

    @include s-640 {
      padding-top: 120px;
    }

    & .c-category-box-el-img img {
      height: 100%;
    }

    & .c-category {
      padding-top: 60px;

      @include s-1920 {
        padding-top: 50px;
      }

      @include s-1440 {
        padding-top: 40px;
      }

      @include s-640 {
        padding-top: 25px;
      }
    }
  }

  & h5 {
    font-family: var(--font-semibold);
    margin-top: 60px;
    line-height: 180%;

    @include s-1920 {
      margin-top: 50px;
    }

    @include s-1440 {
      margin-top: 40px;
    }

    @include s-640 {
      margin-top: 30px;
    }

    & + p {
      margin-top: 20px;

      @include s-1440 {
        margin-top: 15px;
      }

      @include s-640 {
        margin-top: 10px;
      }
    }
  }

  & .el-h8-medium {
    padding-top: 25px;
    display: block;

    @include s-1920 {
      padding-top: 20px;
    }

    @include s-1440 {
      padding-top: 15px;
    }

    @include s-640 {
      padding-top: 10px;
    }
  }

  & .el-title-h4 {
    color: var(--color-categories-8);
    display: block;
    padding: 120px 0;

    @include s-1920 {
      padding: 80px 0;
    }

    @include s-1440 {
      padding: 60px 0;
    }

    @include s-640 {
      padding: 40px 0;
    }

    & + p {
      margin-top: 0;
    }
  }

  & .c-subscribe-box {
    color: var(--color-white);
  }
}

.p-post {
  padding-top: 82px;
  @include s-1024 {
    padding-top: 62px;
  }
  @include s-1024 {
    padding-top: 46px;
  }
  .s-hero {
    position: relative;
    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 0;
      height: 1000px;
      @include s-1920 {
        height: 750px;
      }
      @include s-1440 {
        height: 560px;
      }
      @include s-1024 {
        height: 500px;
      }
      @include s-640 {
        height: 510px;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      position: relative;
      z-index: 1;
      max-width: 2240px;
      margin: 0 auto;
      height: 100%;
      z-index: 1;
      @include s-1920 {
        max-width: 1700px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-1024 {
        max-width: 100%;
      }
      @include s-640 {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &-back{
      padding-top: 60px;
      @include s-1920{
        padding-top: 50px;
      }
      @include s-1440{
        padding-top: 40px;
      }
      @include s-640{
        padding-top: 30px;
      }
      a{
        display: flex;
        align-items: center;
        color: var(--color-grey-2);
        font-size: 20px;
        font-family: var(--font-bold);
        @include s-1920{
          font-size: 18px;
        }
        @include s-1024{
          font-size: 14px;
        }
      }
      .icon{
        width: 20px;
        height: 20px;
        display: block;
        svg{
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .text{
        padding-left: 12px;
        display: block;
        // height: 20px;
        line-height: 1;

      }
    }
    &-img {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      height: 907px;
      @include s-1920 {
        height: 680px;
      }
      @include s-1440 {
        height: 540px;
      }
      @include s-1024 {
        height: 400px;
      }
      @include s-768 {
        height: 290px;
      }
      @include s-640 {
        height: 345px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-top {
      padding-top: 220px;
      color: var(--color-white);
      @include s-1920 {
        padding-top: 150px;

      }
      @include s-1440 {
        padding-top: 100px;

      }
      @include s-1024 {
        padding-top: 90px;

      }
      @include s-768 {
        padding-top: 70px;
      }
      @include s-640 {
        padding-top: 65px;
      }
    }
    &-middle {
      padding-top: 70px;
      @include s-1920 {
        padding-top: 60px;

      }
      @include s-1440 {
        padding-top: 55px;

      }
      @include s-1024 {
        padding-top: 35px;

      }
      @include s-640 {
        padding-top: 25px;

      }
    }
    &-option{
      .info{
        font-family: var(--font-bold); 
        padding-top: 12px;
        font-size: 26px;
        @include s-1920{
          font-size: 20px;
        }
        @include s-1440{
          font-size: 18px;
        }
        @include s-1024{
          font-size: 16px;
        }
        @include s-1024{
          font-size: 14px;
        }
        &:first-of-type{
          padding-top: 0;
        }   

      }
      .label{
        padding-right: 10px;

      }
      .value{

      }
    }
    &-bottom {
      padding-top: 150px;
      @include s-1920{
        padding-top: 120px;
      }
      @include s-1440{
        padding-top: 100px;
      }
      @include s-1024{
        padding-top: 60px;
      }
      @include s-640{
        padding-top: 50px;
      }
      &-wrap {
        max-width: 1030px;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
        @include s-1920{
          max-width: 840px;
        }
        @include s-1440{
          max-width: 680px;
        }
        @include s-768{
          max-width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    &-up{
      display: flex;
      justify-content: center;
      padding-top: 130px;
      @include s-1920{
        padding-top: 100px;
      }
      @include s-1440{
        padding-top: 80px;
      }
      @include s-768{
        padding-top: 65px;
      }
      @include s-640{
        padding-top: 50px;
      }
      &-btns{
        .btn-up{
          width: 48px;
          height: 48px;
          svg{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .s-posts {
    padding-top: 200px;
    padding-bottom: 270px;
    @include s-1920 {
      padding-top: 150px;
      padding-bottom: 150px;
    }
    @include s-1440 {
      padding-top: 100px;
      padding-bottom: 110px;
    }
    @include s-1024 {
      padding-top: 80px;
      padding-bottom: 70px;
    }
    @include s-768 {
      padding-bottom: 60px;
    }
    @include s-640 {
      padding-top: 60px;
      padding-bottom: 80px;
    }
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      max-width: 2260px;
      margin: 0 auto;
      @include s-1920 {
        max-width: 1700px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-640 {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &-list {
      padding-top: 65px;
      @include s-1440 {
        padding-top: 60px;
      }
      @include s-1024 {
        padding-top: 35px;
      }
      @include s-768 {
        padding-top: 20px;
      }
      &-ul {
        display: flex;
        flex-wrap: no-wrap;
        margin: 0 -25px;
        @include s-1440 {
          margin: 0 -14px;
        }
        @include s-1024 {
          margin: 0 -17px;
        }
        @include s-768 {
          margin: 0 -9px;
          overflow: auto;
        }
        @include s-640 {
          margin: 0 -7px;
        }
      }
      &-li {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 80px;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        @include s-1920 {
          padding-bottom: 70px;
        }
        @include s-1440 {
          padding-left: 14px;
          padding-right: 14px;
          padding-bottom: 60px;
        }
        @include s-1024 {
          padding-left: 17px;
          padding-right: 17px;
          padding-bottom: 40px;
        }
        @include s-768 {
          // flex: 0 0 50%;
          // max-width: 50%;
          padding-bottom: 40px;
          padding-left: 9px;
          padding-right: 9px;
          flex: 0 0 344px;
          max-width: 344px;
        }
        @include s-640 {
          flex: 0 0 344px;
          max-width: 344px;
          padding-left: 7px;
          padding-right: 7px;
         }
      }
      .item {
        &-img {
          width: 100%;
          height: 445px;
          border-radius: 6px;
          overflow: hidden;
          @include s-1920 {
            height: 330px;
          }
          @include s-1440 {
            height: 265px;
          }
          @include s-1024 {
            height: 190px;
          }
          @include s-768 {
            height: 212px;
          }
          @include s-640 {
            height: 206px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &-cat {
          padding-top: 20px;
          display: flex;
          @include s-1920 {
            padding-top: 15px;
          }
          @include s-1440 {
            padding-top: 12px;
          }
          &-title {
            margin-left: 10px;
            padding: 8px 25px 6px;
            border: 1px solid var(--color-black);
            border-radius: 20px;
            font-family: var(--font-bold-tele);
            font-size: 16px;
            text-transform: uppercase;
            line-height: 130%;
            @include s-1440 {
              font-size: 12px;
              padding: 8px 15px 5px;
              line-height: 100%;
            }
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
        &-title {
          padding-top: 20px;
          @include s-1920 {
            padding-top: 15px;
          }
          @include s-1440 {
            padding-top: 12px;
          }
          h5 {
            font-family: var(--font-regular-tele);
            font-size: 42px;
            line-height: 140%;
            font-size: calcFluidFontSize(34, 42, 1920, 2560);
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            @include s-1920 {
              font-size: calcFluidFontSize(28, 34, 1440, 1920);
            }

            @include s-1440 {
              font-size: calcFluidFontSize(24, 28, 1024, 1440);
            }

            @include s-768 {
              font-size: calcFluidFontSize(20, 24, 640, 768);
            }

            @include s-640 {
              font-size: 20px;
            }
          }
        }
        &-desc {
          padding-top: 20px;
          font-size: 20px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          @include s-1440 {
            padding-top: 12px;
            font-size: 16px;
          }
          @include s-1024 {
            padding-top: 10px;
            font-size: 14px;
          }
        }
        &-link {
          padding-top: 20px;
          @include s-1440 {
            padding-top: 15px;
          }
          @include s-640 {
            padding-top: 18px;
          }
          .link-grad-arrow {
            font-family: var(--font-bold-tele);
          }
        }
      }
    }
  }
}
