.p-product{
    padding-top: 82px;
    @include s-1024{
      padding-top: 62px;
    }
    @include s-1024{
      padding-top: 46px;
    }
    .s{
        &-wrap{
          padding-left: 40px;
          padding-right: 40px;
          margin: 0 auto;
          max-width: 2240px;
          @include s-1920{
            max-width: 1706px;
          }
          @include s-1440{
            max-width: 1360px;
          }
          @include s-1024{
            max-width: 100%
          }
          @include s-640{
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
    .s-product{
        padding-top: 25px;
        &-top{
            &-wrap{
                padding-left: 50px;
                padding-right: 50px;
                a{
                    display: flex;
                    align-items: center;
                    color: var(--color-black);
                    font-size: 20px;
                    font-family: var(--font-bold);
                    @include s-1920{
                      font-size: 18px;
                    }
                    @include s-1024{
                      font-size: 14px;
                    }
                }
                .icon{
                width: 20px;
                height: 20px;
                display: block;
                svg{
                    width: 100%;
                    height: 100%;
                    display: block;
                }
                }
                .text{
                padding-left: 12px;
                display: block;

                line-height: 1;            
                }
            } 
        }
        &-wrap{
            padding-top: 20px;
            display: flex;
            flex-wrap: nowrap;
            padding-left: 50px;
            padding-right: 50px;
            @include s-640{
              padding-left: 15px;
              padding-right: 15px;
            }
        }
        &-img{
            flex: 0 0 50%;
            max-width: 50%;
            .item{
                &-img{
                    width: 100%;
                    img{
                        width: 100%;
                    }
                }
            }
        }
        &-desc{
            flex: 0 0 50%;
            max-width: 50%;
            padding-top: 140px;
            padding-left: 220px;
            @include s-1920{
              padding-left: 120px;
            }
            @include s-1440{
              padding-left: 60px;
            }
            @include s-1024{
              padding-left: 40px;
            }
            @include s-640{
              padding-top: 25px;
              flex: 0 0 100%;
              max-width: 100%;
              padding-left: 15px;
              padding-right: 15px;
            }
            .tem{
              &-title{

              }
              &-desc{
                padding-top: 25px;
              }
              &-price{
                padding-top: 50px;
              }
              &-link{
                padding-top: 30px;
              }
            }
        }
    }
    .s-for{
      padding-top: 260px;
      padding-bottom: 260px;
        &-wrap{

        }
        &-faqs{
          flex: 0 0 50%;
          max-width: 50%;
        }
        &-img{
          flex: 0 0 50%;
          max-width: 50%;
          .item{
              &-img{
                width: 100%;
                img{
                  width: 100%;
                }
              }
          }
        }
    }


    &-fit{
        background-color: var(--color-black);
        // background-color: black;
        .s-hero{ 
            position: relative;
            height: 1000px;
            @include s-1920{
              height: 550px;
            }
            @include s-1440{
              height: 480px;
            }
            @include s-1024{
              height: 390px;
            }
            @include s-640{
              height: 260px;
            }
            &-wrap{
              padding-left: 40px;
              padding-right: 40px;
              display: flex;
              flex-wrap: nowrap;
              position: relative;
              z-index: 1;
              max-width: 2240px;
              margin: 0 auto;
              height: 100%;
              @include s-1920{
                max-width: 1700px;
              }
              @include s-1440{
                max-width: 1360px;
              }
              @include s-1024{
                max-width: 100%;
              }
              @include s-640{
                padding-left: 30px;
                padding-right: 30px;
              }
        
            }
            &-desc{
              flex: 0 0 50%;
              max-width: 50%;
              flex: 1;
              padding-right: 190px;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              @include s-768{
                padding-right: 0;
                flex: 0 0 100%;
                max-width: 100%;
              }
              .item{
                max-width: 1080px;
                @include s-1920{
                  max-width: 602px;
                }
                @include s-1440{
                  max-width: 570px;
                }
                @include s-1024{
                  max-width: 517px;
                }
                @include s-768{
                  max-width: 508px;
                }
                @include s-640{
                  max-width: 100%;
                }
                h1{
                  color: var(--color-white)
                }
                &-desc{
                  padding-top: 50px;
                  max-width: 430px;
                  color: var(--color-white);
                  font-size: 24px;
                  line-height: 145%;
                  padding-top: 50px;
                  max-width: 430px;
                  color: var(--color-white);
                  font-size: 24px;
                  line-height: 145%;
                  @include s-1920{
                    max-width: 410px;
                    font-size: 20px;
                    padding-top: 40px;
                  }
                  @include s-1440{
                    max-width: 380px;
                    font-size: 18px;
                    padding-top: 30px;
                  }
                  @include s-1024{
                    font-size: 14px;
                    padding-top: 25px;
                  }
                  @include s-640{
                    padding-top: 15px;
                    max-width: 100%;
                  }
                }
              }
        
            }
            &-img{
              flex: 0 0 50%;
              max-width: 50%;
            }
            &-bg{
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 0;
              img{
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
        
          }
    }
}