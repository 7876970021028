a {
  font-family: var(--font-medium);
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

p {
  margin-top: 60px;
  line-height: 180%;

  @include s-1920 {
    margin-top: 50px;
  }

  @include s-1440 {
    margin-top: 40px;
  }

  @include s-640 {
    margin-top: 30px;
  }
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

h1,
.el-title--h1 {
  font-family: var(--font-regular-tele);
  line-height: 100%;
  font-size: calcFluidFontSize(120, 160, 1920, 2560);
  font-weight: 400;

  @include s-1920 {
    font-size: calcFluidFontSize(92, 120, 1440, 1920);
  }

  @include s-1440 {
    font-size: calcFluidFontSize(72, 92, 1024, 1440);
  }

  @include s-1024 {
    font-size: 72px;
  }

  @include s-768 {
    font-size: calcFluidFontSize(54, 72, 640, 768);
  }

  @include s-640 {
    font-size: 54px;
  }
}

h2,
.el-title--h2 {
  line-height: 140%;
  font-family: var(--font-regular-tele);
  font-size: calcFluidFontSize(58, 74, 1920, 2560);
  font-weight: 400;

  @include s-1920 {
    font-size: calcFluidFontSize(46, 58, 1440, 1920);
  }

  @include s-1440 {
    font-size: calcFluidFontSize(36, 46, 1024, 1440);
  }

  @include s-1024 {
    font-size: 36px;
  }

  @include s-768 {
    font-size: calcFluidFontSize(30, 36, 640, 768);
  }

  @include s-640 {
    font-size: 30px;
  }
}

h3,
.el-title-h3 {
  line-height: 100%;
  font-size: calcFluidFontSize(56, 66, 1920, 2560);
  font-weight: 400;

  @include s-1920 {
    font-size: calcFluidFontSize(48, 56, 1440, 1920);
  }

  @include s-1440 {
    font-size: 48px;
  }

  @include s-768 {
    font-size: calcFluidFontSize(28, 48, 640, 768);
  }

  @include s-640 {
    font-size: 28px;
  }
}

h4,
.el-title-h4 {
  line-height: 120%;
  font-size: calcFluidFontSize(28, 34, 1920, 2560);

  @include s-1920 {
    font-size: calcFluidFontSize(24, 28, 1440, 1920);
  }

  @include s-1440 {
    font-size: calcFluidFontSize(22, 24, 1024, 1440);
  }

  @include s-1024 {
    font-size: 22px;
  }

  @include s-768 {
    font-size: calcFluidFontSize(20, 22, 640, 768);
  }

  @include s-640 {
    font-size: 20px;
  }

  &.semi {
    font-family: var(--font-semibold);
  }
}

blockquote,
h5,
.el-title-h5 {
  line-height: 140%;
  font-size: calcFluidFontSize(24, 28, 1920, 2560);
  font-weight: 400;

  @include s-1920 {
    font-size: calcFluidFontSize(20, 24, 1440, 1920);
  }

  @include s-1440 {
    font-size: calcFluidFontSize(20, 20, 1024, 1440);
  }

  @include s-1024 {
    font-size: calcFluidFontSize(20, 20, 768, 1024);
  }

  @include s-768 {
    font-size: calcFluidFontSize(15, 20, 640, 768);
  }

  @include s-640 {
    font-size: 15px;
  }
}

h6,
.el-title--h6 {
  line-height: 140%;
  font-size: calcFluidFontSize(20, 26, 1920, 2560);

  @include s-1920 {
    font-size: calcFluidFontSize(16, 20, 1440, 1920, 2560);
  }

  @include s-1440 {
    font-size: calcFluidFontSize(16, 16, 1440, 1024, 1440);
  }

  @include s-1024 {
    font-size: calcFluidFontSize(15, 16, 768, 1024);
  }

  @include s-768 {
    font-size: 15px;
  }
}

.el-title--h7 {
  line-height: 140%;
  font-size: calcFluidFontSize(16, 20, 1920, 2560);

  @include s-1920 {
    font-size: calcFluidFontSize(15, 16, 1440, 1920);
  }

  @include s-1440 {
    font-size: 15px;
  }

  @include s-768 {
    font-size: calcFluidFontSize(14, 15, 640, 768);
  }

  @include s-640 {
    font-size: 14px;
  }
}

.el-h8-medium {
  font-family: var(--font-medium);
  text-transform: uppercase;
  letter-spacing: 0.02em;
  line-height: 125%;
  font-size: calcFluidFontSize(14, 16, 1920, 2560);

  @include s-1920 {
    font-size: calcFluidFontSize(12, 14, 1440, 1920);
  }

  @include s-1440 {
    font-size: 12px;
  }
}

blockquote {
  position: relative;
  z-index: 1;
  margin: 100px -40px 0 -40px;
  background-color: var(--color-categories-10);
  padding: 40px;
  line-height: 140%;
  font-family: var(--font-semibold);

  @include s-1920 {
    margin: 50px -40px 0 -40px;
  }

  @include s-1440 {
    margin: 40px -40px 0 -40px;
  }

  @include s-640 {
    padding: 15px;
    margin: 40px -15px 0 -15px;
  }
}

b,
strong {
  font-family: var(--font-semibold);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input,
textarea
{
  padding: 20px 22px;
  color: var(--color-black);
  border-radius: 0;
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
  font-size: 20px;
  line-height: 145%;
  border: 1px solid #8F8F8F;
  border-radius: 4px;

  @include s-1920 {
    padding: 18px 20px;
    font-size: 18px;
  }

  @include s-1440 {
    font-size: 16px;
  }

  @include s-1024 {
    font-size: 14px;
    padding: 16px 18px;
  }

  &[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 10px;
    width: 0;
    height: 0;

    &:before {
      content: "";
      width: 24px;
      height: 24px;
      border: 1px solid var(--color-grey);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);

      @include s-1440 {
        width: 20px;
        height: 20px;
      }
    }

    &:after {
      content: "";
      width: 16px;
      height: 16px;
      background-color: var(--color-categories-9);
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translate(0, -50%);
      opacity: 0;

      @include s-1440 {
        width: 12px;
        height: 12px;
      }
    }

    &:checked {
      &:after {
        opacity: 1;
      }
    }
  }

  @include s-1440 {
    padding: 12px 10px;
  }
}

textarea {
  resize: none;
}

.el {
  &-input {
    position: relative;
    z-index: 1;
    margin-bottom: 66px;

    @include s-1920 {
      margin-bottom: 54px;
    }

    @include s-1440 {
      margin-bottom: 44px;
    }

    @include s-1440 {
      margin-bottom: 46px;
    }

    &.active,
    &.filled {
      &:after {
        content: "";
        height: 1px;
      }

      & label {
        left: 15px;
        top: 7px;
        font-size: 12px;
        transform: translate(0, 0);
        color: var(--color-grey);

        @include s-1440 {
          left: 10px;
          top: 5px;
          font-size: 11px;
        }
      }
    }

    &.filled {
      & label {
        opacity: 0;
      }
    }

    & label {
      top: -20px;
      position: absolute;
      left: 0;
      line-height: 100%;
      color: var(--color-black);
      transition: all ease-out 0.3s;
      z-index: -1;
      font-size: 16px;

      @include s-1920 {
        font-size: 14px;
      }

      @include s-1440 {
        font-size: 12px;
      }
    }
  }

  &-checkbox {
    position: relative;

    & label {
      padding-left: 44px;
      font-size: 20px;
      display: block;
      cursor: pointer;
      letter-spacing: 0.02em;

      @include s-1920 {
        font-size: calcFluidFontSize(16, 20, 1440, 1920);
      }

      @include s-1440 {
        font-size: calcFluidFontSize(14, 16, 1024, 1440);
        padding-left: 35px;
      }

      @include s-1024 {
        font-size: 14px;
      }

      @include s-640 {
        display: block;
      }
    }

    & a {
      text-decoration: underline;
      display: inline;
      margin-left: 5px;
    }
  }

  &-question {
    color: var(--color-white);
  }
}

.c-spinner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn,
button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}

.btn-grad {
  // padding: 0;
  padding: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  z-index: 3;
  width: 100%;
  background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
  border-radius: 50px;
  max-width: 320px;
  @include s-1920 {
    max-width: 250px;
  }
  @include s-1440 {
    max-width: 200px;
  }
  @include s-1024 {
    max-width: 190px;
  }
  .btn-wrap {
    color: var(--color-white);
    font-family: var(--font-bold-tele);
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;
    // padding: 11px 10px 11px;
    padding: 25px 10px 24px;
    border-radius: 50px;
    background-color: var(--color-white);
    // margin: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    position: relative;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    z-index: 3;
    @include s-1920 {
      font-size: 16px;
      padding: 21px 10px 20px;
    }
    @include s-1440 {
      font-size: 12px;
      padding: 18px 10px 18px;
    }
    @include s-1024 {
      padding: 16px 10px 16px;
    }
    &:before {
      content: "";
      z-index: 0;
      position: absolute;
      background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
      border-radius: 50px;
      left: 3px;
      top: 3px;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      padding: 2px;
      opacity: 1;
      filter: blur(2px);
    }
  }
  .btn-text {
    position: relative;
    z-index: 3;
  }
  &:hover {
    .btn-wrap {
      color: var(--color-white);
      &:before {
        filter: blur(0);
      }
    }
  }
}

.btn-grad-bg {
  // padding: 0;
  padding: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  z-index: 3;
  width: 100%;
  background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
  border-radius: 50px;
  max-width: 320px;
  @include s-1920 {
    max-width: 250px;
  }
  @include s-1440 {
    max-width: 200px;
  }
  @include s-1024 {
    max-width: 190px;
  }
  .btn-wrap {
    color: var(--color-white);
    font-family: var(--font-bold-tele);
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;
    padding: 25px 10px 24px;
    border-radius: 50px;
    background-color: var(--color-black);
    // margin: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    position: relative;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    z-index: 3;
    @include s-1920 {
      font-size: 16px;
      padding: 21px 10px 20px;
    }
    @include s-1440 {
      font-size: 12px;
      padding: 18px 10px 18px;
    }
    @include s-1024 {
      padding: 16px 10px 16px;
    }
    &:before {
      content: "";
      z-index: 0;
      position: absolute;
      background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
      border-radius: 50px;
      left: 3px;
      top: 3px;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      padding: 2px;
      opacity: 1;
      filter: blur(2px);
    }
  }
  .btn-text {
    position: relative;
    z-index: 3;
  }
  &:hover {
    .btn-wrap {
      color: var(--color-white);
      &:before {
        filter: blur(0);
      }
    }
  }
}

.btn-arrow {
  // padding: 0;
  padding: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  z-index: 3;
  width: 100%;
  background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
  border-radius: 50px;
  height: 72px;
  max-width: 320px;
  @include s-1920 {
    max-width: 250px;
  }
  @include s-1440 {
    max-width: 200px;
  }
  @include s-1024 {
    max-width: 190px;
  }
  .btn-wrap {
    color: var(--color-black);
    font-family: var(--font-bold-tele);
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;
    padding: 25px 10px 24px;
    border-radius: 50px;
    background-color: var(--color-white);
    // margin: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    position: relative;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    z-index: 3;
    @include s-1920 {
      font-size: 16px;
      padding: 21px 10px 20px;
    }
    @include s-1440 {
      font-size: 12px;
      padding: 18px 10px 18px;
    }
    @include s-1024 {
      padding: 16px 10px 16px;
    }
    &:before {
      content: "";
      z-index: 0;
      position: absolute;
      background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
      border-radius: 50px;
      left: 3px;
      top: 3px;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      padding: 2px;
      opacity: 0;
      // filter: blur(2px);
    }
  }
  .btn-text {
    position: relative;
    z-index: 3;
  }
  svg {
    position: relative;
    z-index: 3;
    width: 18px;
    height: 18px;
    transform: translateY(-2px);
    margin-left: 7px;
    display: block;
    path {
      stroke: var(--color-black);
    }
  }
  &:hover {
    .btn-wrap {
      color: var(--color-white);
      &:before {
        opacity: 1;
      }
    }
    svg {
      path {
        stroke: var(--color-white);
      }
    }
  }
}

.btn-arrow-up {
  // padding: 0;
  padding: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  z-index: 3;
  width: 100%;
  background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
  border-radius: 50px;
  max-width: 320px;
  @include s-1920 {
    max-width: 250px;
  }
  @include s-1440 {
    max-width: 200px;
  }
  @include s-1024 {
    max-width: 190px;
  }
  .btn-wrap {
    color: var(--color-black);
    text-transform: uppercase;
    font-family: var(--font-bold-tele);
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;
    padding: 25px 10px 24px;
    border-radius: 50px;
    background-color: var(--color-white);
    // margin: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    position: relative;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    z-index: 3;
    @include s-1920 {
      font-size: 16px;
      padding: 21px 10px 20px;
    }
    @include s-1440 {
      font-size: 12px;
      padding: 18px 10px 18px;
    }
    @include s-1024 {
      padding: 16px 10px 16px;
    }
    &:before {
      content: "";
      z-index: 0;
      position: absolute;
      background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
      border-radius: 50px;
      left: 3px;
      top: 3px;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      padding: 2px;
      opacity: 0;
      // filter: blur(2px);
    }
  }
  .btn-text {
    position: relative;
    z-index: 3;
  }
  svg {
    position: relative;
    z-index: 3;
    width: 18px;
    height: 18px;
    transform: translateY(-2px);
    margin-left: 7px;
    display: block;
    path {
      stroke: var(--color-black);
    }
  }
  &:hover {
    .btn-wrap {
      color: var(--color-white);
      &:before {
        opacity: 1;
      }
    }
    svg {
      path {
        stroke: var(--color-white);
      }
    }
  }
}

.btn-border-bg {
  // padding:0;
  padding: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  z-index: 3;
  width: 100%;
  background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
  border-radius: 50px;
  max-width: 320px;
  @include s-1920 {
    max-width: 250px;
  }
  @include s-1440 {
    max-width: 200px;
  }
  @include s-1024 {
    max-width: 190px;
  }
  .btn-wrap {
    color: var(--color-white);
    font-family: var(--font-bold-tele);
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;
    padding: 25px 10px 24px;
    border-radius: 50px;
    background-color: var(--color-black);
    // margin: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    position: relative;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    z-index: 3;
    @include s-1920 {
      font-size: 16px;
      padding: 21px 10px 20px;
    }
    @include s-1440 {
      font-size: 12px;
      padding: 18px 10px 18px;
    }
    @include s-1024 {
      padding: 16px 10px 16px;
    }
    &:before {
      content: "";
      z-index: 0;
      position: absolute;
      background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
      border-radius: 50px;
      left: 3px;
      top: 3px;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      padding: 2px;
      opacity: 0;
    }
  }
  .btn-text {
    position: relative;
    z-index: 3;
  }
  &:hover {
    .btn-wrap {
      &:before {
        opacity: 1;
      }
    }
  }
}

.btn-grad-cover {
  padding: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  z-index: 3;
  width: 100%;
  background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
  border-radius: 50px;
  max-width: 320px;
  @include s-1920 {
    max-width: 250px;
  }
  @include s-1440 {
    max-width: 200px;
  }
  @include s-1024 {
    max-width: 190px;
  }
  .btn-wrap {
    color: var(--color-black);
    font-family: var(--font-bold-tele);
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;
    // padding: 11px 10px 11px;
    padding: 25px 10px 24px;
    border-radius: 50px;
    background-color: var(--color-white);
    margin: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    position: relative;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    z-index: 3;
    @include s-1920 {
      font-size: 16px;
      padding: 21px 10px 20px;
    }
    @include s-1440 {
      font-size: 12px;
      padding: 18px 10px 18px;
    }
    @include s-1024 {
      padding: 16px 10px 16px;
    }
    // &:before{
    //     content: '';
    //     z-index: 0;
    //     position: absolute;
    //     background-image: linear-gradient(90deg, #2F7E62 -26.62%, #969913 48.26%, #F9A01A 115.02%);
    //     border-radius: 50px;
    //     left: 3px;
    //     top: 3px;
    //     width: calc(100% - 10px);
    //     height: calc(100% - 10px);
    //     padding: 2px;
    //     opacity: 1;
    //     filter: blur(2px);
    // }
  }
  .btn-text {
    position: relative;
    z-index: 3;
  }
  &:hover {
    .btn-wrap {
      color: var(--color-white);
      background-color: transparent;
      &:before {
        // filter: blur(0);
      }
    }
  }
}

.btn-grad-black {
  // padding: 0;
  padding: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  z-index: 3;
  width: 100%;
  background-color: var(--color-black);
  border-radius: 50px;
  max-width: 320px;
  @include s-1920 {
    max-width: 250px;
  }
  @include s-1440 {
    max-width: 200px;
  }
  @include s-1024 {
    max-width: 190px;
  }
  .btn-wrap {
    color: var(--color-white);
    font-family: var(--font-bold-tele);
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;
    padding: 25px 10px 24px;
    border-radius: 50px;
    background-color: var(--color-white);
    // margin: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    position: relative;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    z-index: 3;
    @include s-1920 {
      font-size: 16px;
      padding: 21px 10px 20px;
    }
    @include s-1440 {
      font-size: 12px;
      padding: 18px 10px 18px;
    }
    @include s-1024 {
      padding: 16px 10px 16px;
    }
    &:before {
      content: "";
      z-index: 0;
      position: absolute;
      background-color: var(--color-black);
      border-radius: 50px;
      left: 3px;
      top: 3px;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      padding: 2px;
      opacity: 1;
      filter: blur(2px);
    }
  }
  .btn-text {
    position: relative;
    z-index: 3;
  }
  &:hover {
    .btn-wrap {
      color: var(--color-white);
      &:before {
        filter: blur(0);
      }
    }
  }
}

.btn-bg {
  padding: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  z-index: 3;
  width: 100%;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 50px;
  font-family: var(--font-bold-tele);
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.05em;
  padding: 25px 10px 24px;
  max-width: 320px;
  @include s-1920 {
    font-size: 16px;
    padding: 21px 10px 20px;
    max-width: 250px;
  }
  @include s-1440 {
    font-size: 12px;
    padding: 18px 10px 18px;
    max-width: 200px;
  }
  @include s-1024 {
    padding: 16px 10px 16px;
    max-width: 190px;
  }

  &:hover {
    color: var(--color-white);
    background-color: var(--color-black);
  }
}

.btn-border {
  // padding: 0;
  padding: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  z-index: 3;
  width: 100%;
  background-color: var(--color-grey-3);
  border-radius: 50px;
  max-width: 320px;
  @include s-1920 {
    max-width: 250px;
  }
  @include s-1440 {
    max-width: 200px;
  }
  @include s-1024 {
    max-width: 190px;
  }
  .btn-wrap {
    color: var(--color-black);
    font-family: var(--font-bold-tele);
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;
    padding: 25px 10px 24px;
    border-radius: 50px;
    background-color: var(--color-white);
    // margin: 1px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    position: relative;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    z-index: 3;
    @include s-1920 {
      font-size: 16px;
      padding: 21px 10px 20px;
    }
    @include s-1440 {
      font-size: 12px;
      padding: 18px 10px 18px;
    }
    @include s-1024 {
      padding: 16px 10px 16px;
    }
  }
  .btn-text {
    position: relative;
    z-index: 3;
  }
  &:hover {
    background-color: transparent;
    background-image: linear-gradient(90deg, #2f7e62 -26.62%, #969913 48.26%, #f9a01a 115.02%);
  }
}

.link-arrow {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  &-text {
    color: var(--color-black);
    font-size: 20px;
    font-family: var(--font-bold-tele);
    padding-right: 12px;
    line-height: 130%;
    height: 20px;
    @include s-1920 {
      font-size: 18px;
      padding-right: 8px;
      height: 18px;
    }
    @include s-1440 {
      font-size: 14px;
      padding-right: 4px;
      height: 14px;
    }
  }
  &-icon {
    width: 20px;
    height: 20px;
    @include s-1920 {
      width: 18px;
      height: 18px;
    }
    @include s-1440 {
      font-size: 14px;
      width: 12px;
      height: 12px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.link-arrow-grad {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  &-text {
    background: linear-gradient(90deg, #2f7e62 -34.83%, #969913 48.44%, #f9a01a 137.64%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 20px;
    font-family: var(--font-bold-tele);
    padding-right: 12px;
    display: block;
    line-height: 130%;
    height: 20px;
    @include s-1920 {
      font-size: 18px;
      padding-right: 8px;
      height: 18px;
    }
    @include s-1440 {
      font-size: 14px;
      padding-right: 4px;
      height: 14px;
    }
  }
  &-icon {
    width: 20px;
    height: 20px;
    display: block;
    @include s-1920 {
      width: 18px;
      height: 18px;
    }
    @include s-1440 {
      font-size: 14px;
      width: 12px;
      height: 12px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.c-article-content {
  & > * {
    width: 100%;
    max-width: 830px;
    margin: 0 auto;
    @include s-1920 {
      max-width: 780px;
    }
    @include s-1440 {
      max-width: 638px;
    }
  }

  & > img {
    max-width: 100%;
    margin-top: 100px;
    // margin-bottom: 100px;
    @include s-1920 {
      margin-top: 70px;
      // margin-bottom: 70px;
    }
    @include s-1440 {
      margin-top: 60px;
      // margin-bottom: 60px;
    }
    @include s-640 {
      margin-top: 45px;
      // margin-bottom: 45px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 100px;
    line-height: 150%;
    font-family: var(--font-bold);
    @include s-1920 {
      padding-top: 70px;
    }
    @include s-1440 {
      padding-top: 60px;
    }
    @include s-640 {
      padding-top: 45px;
    }
  }
  h1 {
    font-size: 44px;
    @include s-1920 {
      font-size: 36px;
    }
    @include s-1440 {
      font-size: 32px;
    }
    @include s-640 {
      font-size: 26px;
    }
  }
  h2 {
    font-size: 44px;
    @include s-1920 {
      font-size: 36px;
    }
    @include s-1440 {
      font-size: 32px;
    }
    @include s-640 {
      font-size: 26px;
    }
  }
  h3 {
    font-size: 44px;
    @include s-1920 {
      font-size: 36px;
    }
    @include s-1440 {
      font-size: 32px;
    }
    @include s-640 {
      font-size: 26px;
    }
  }
  h4 {
    font-size: 32px;
    @include s-1920 {
      font-size: 28px;
    }
    @include s-1440 {
      font-size: 24px;
    }
    @include s-640 {
      font-size: 22px;
    }
  }
  h5 {
    font-size: 28px;
    @include s-1920 {
      font-size: 24px;
    }
    @include s-1440 {
      font-size: 22px;
    }
    @include s-640 {
      font-size: 20px;
    }
  }
  h6 {
    font-size: 24px;
    @include s-1920 {
      font-size: 22px;
    }
    @include s-1440 {
      font-size: 20px;
    }
    @include s-640 {
      font-size: 18px;
    }
  }

  p {
    padding-top: 40px;
    font-size: 24px;
    line-height: 150%;
    @include s-1920 {
      font-size: 20px;
      padding-top: 30px;
    }
    @include s-1440 {
      font-size: 16px;
      padding-top: 25px;
    }
    @include s-1024 {
      font-size: 16px;
    }
    @include s-768 {
      padding-top: 30px;
    }
    @include s-640 {
      font-size: 15px;
      //  padding-top: 15px;
    }
    &:first-of-type {
      padding-top: 0;
    }
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
    line-height: 160%;
    display: block;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
    padding-left: 20px;
    // color: var(--color-red);
    font-size: 24px;
    line-height: 150%;
    counter-reset: item;
    @include s-1920 {
      font-size: 20px;
    }
    @include s-1440 {
      font-size: 16px;
    }
    @include s-1024 {
      font-size: 16px;
    }
    @include s-640 {
      font-size: 15px;
    }
    li {
      // display: block;
    }
  }
  li {
    padding-top: 16px;
    font-size: 24px;
    line-height: 150%;
    color: var(--color-grey);
    @include s-1920 {
      font-size: 20px;
    }
    @include s-1440 {
      font-size: 16px;
    }
    @include s-1024 {
      font-size: 16px;
    }
    @include s-640 {
      font-size: 15px;
    }
    &:list-style-type {
      padding-top: 0;
    }
  }
  a {
    color: var(--color-red);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  strong {
    font-family: var(--font-bold);
  }
  em {
    font-style: italic;
  }

  blockquote {
    color: var(--color-black);
    font-size: 28px;
    line-height: 150%;
    margin-top: 70px;
    margin-bottom: 70px;
    padding: 30px 50px;
    position: relative;
    background-color: transparent;
    font-family: var(--font-bold);
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 6px;
      height: 100%;
      content: "";
      background: linear-gradient(360deg, #2f7e62 -34.83%, #969913 48.44%, #f9a01a 137.64%);
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-size: contain;
    }

    @include s-1920 {
      font-size: 28px;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    @include s-1440 {
      font-size: 24px;
      margin-top: 45px;
      margin-bottom: 45px;
    }
    @include s-640 {
      font-size: 22px;
      margin-top: 40px;
      margin-bottom: 40px;
      padding: 15px;
    }
  }
}
