.c-product {
  &-icon {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 48px;
    height: 48px;
    @include s-1920{
      
    }
    @include s-1440{
      
    }
    @include s-1024{
      
    }
    @include s-768{
      
    }
    @include s-640{
      
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &-img {
    position: relative;
    img{
      width: 100%;
    }
  }
  &-text {
    padding-top: 25px;
    height: 165px;
    @include s-1920{
      
    }
    @include s-1440{
      
    }
    @include s-1024{
      
    }
    @include s-768{
      
    }
    @include s-640{
      
    }
  }
  &-title {
    font-size: 34px;
    line-height: 140%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    @include s-1920{
      font-size: 24px;
    }
    @include s-1440{
      font-size: 20px;
    }
    @include s-1024{
      font-size: 18px;
    }
    @include s-768{
      
    }
    @include s-640{
      font-size: 16px;
    }
  }
  &-price {
    padding-top: 15px;
    font-size: 24px;
    color: var(--color-grey);
    @include s-1920{
      font-size: 20px;
    }
    @include s-1440{
      font-size: 16px;
    }
    @include s-1024{
      font-size: 14px;
    }
    @include s-768{
      
    }
    @include s-640{
      
    } 
  }
  &-btns {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    margin: 0 -13px;
    @include s-1920{
      padding-top: 15px;
    }
  }
  &-btn{
    padding-left: 13px;
    padding-right: 13px;
    flex:  0 0 50%;
    max-width: 50%;
  }
}
