.p-faqs,.p-terms {
  padding-top: 82px;
  @include s-1024{
    padding-top: 62px;
  }
  @include s-1024{
    padding-top: 46px;
  }

  .s {
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      margin: 0 auto;
      max-width: 2240px;
      display: flex;
      flex-wrap: nowrap;
      @include s-1920 {
        max-width: 1706px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-1024 {
        max-width: 100%;
      }
      @include s-640 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  .s-hero {
      position: relative;
      height: 1080px;
      @include s-1920 {
        height: 750px;
      }
      @include s-1440 {
        height: 550px;
      }
      @include s-1024 {
        height: 500px;
      }
      @include s-640 {
        height: 510px;
      }
      &-wrap {
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        z-index: 1;
        max-width: 2240px;
        margin: 0 auto;
        height: 100%;
        @include s-1920 {
          max-width: 1700px;
        }
        @include s-1440 {
          max-width: 1360px;
        }
        @include s-1024 {
          max-width: 100%;
        }
        @include s-640 {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
      &-desc {
          //   flex: 0 0 50%;
          //   max-width: 50%;
          // padding-right: 190px;
        flex: 1;

        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include s-768 {
        //   padding-right: 0;
          flex: 0 0 100%;
          max-width: 100%;
        }
        .item {
          max-width: 1080px;
          // @include s-1920 {
          //   max-width: 602px;
          // }
          // @include s-1440 {
          //   max-width: 570px;
          // }
          // @include s-1024 {
          //   max-width: 517px;
          // }
          @include s-768 {
            max-width: 508px;
          }
          @include s-640 {
            max-width: 100%;
          }
          h1 {
            color: var(--color-white);
          }
          &-desc {
            padding-top: 50px;
            max-width: 580px;
            color: var(--color-white);
            font-size: 24px;
            line-height: 145%;
            @include s-1920 {
              max-width: 480px;
              font-size: 20px;
              padding-top: 40px;
            }
            @include s-1440 {
              max-width: 380px;
              font-size: 18px;
              padding-top: 30px;
            }
            @include s-1024 {
              font-size: 14px;
              padding-top: 25px;
            }
            @include s-640 {
              padding-top: 15px;
              max-width: 100%;
            }
          }
          &-link {
            padding-top: 70px;
            max-width: 320px;
            @include s-1920 {
              max-width: 250px;
              padding-top: 50px;
            }
            @include s-1440 {
              max-width: 200px;
            }
            @include s-1024 {
              max-width: 190px;
            }
          }
        }
      }
      &-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
            display: none;
            @include s-768{
              display: block;
            }  
          }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: right;
        }
      }
  }

  .s-content{
    padding-top: 200px;
    padding-bottom: 305px;
    position: relative;
    &::after{
      content: ''; 
      position: absolute;
      left: 0;
      bottom: 0;
      height: 120px;
      width: 100%;
      background: linear-gradient(180deg, #F4F4F4 3.54%, #A0971F 35.9%, #608C40 76.02%);
      filter: blur(81px);
    }
    @include s-1920{
      padding-top: 150px;
      padding-bottom: 250px;
    }
    @include s-1440{
      padding-top: 120px;
      padding-bottom: 200px;
      &::after{
        height: 95px;
      }
    }
    @include s-1024{
      padding-top: 90px;
      max-width: 688px;
      margin: 0 auto;
      padding-bottom: 160px;
    }
    @include s-768{
      padding-top: 70px;
      max-width: 100%;
      &::after{
        height: 80px;
      }
    }
    @include s-640{
      padding-top: 60px;
      padding-bottom: 100px;
      &::after{
        height: 70px;
        filter: blur(20px);
      }
    }
    .s-wrap{
       @include s-1024{
        flex-wrap: wrap;
       }
    }
  }

  .aside{
    flex: 1;
    @include s-1024{
      flex: 0 0 100%;
      max-width: 100%;
    }
    &-block{
      max-width: 580px;
      position: sticky;
      top: 160px;

      @include s-1920{
        max-width: 422px;
      }
      @include s-1440{
        max-width: 300px;
      }
      @include s-1024{
        max-width: 100%;
      }
      @include s-768{
 
      }
    }

    &-ul{

    }

    &-li{
      padding-bottom: 20px;
      @include s-1024{
        padding-bottom: 0;
      }
      .btn{
        color: var(--color-grey);
        line-height: 140%;
        position: relative;
        @include s-1024{
          padding: 18px 20px;
          background-color: var(--color-white);
          color: var(--color-black);
        }
        &.active{
          padding-left: 25px;
          font-family: var(--font-bold);
          &::before{
            content: '';
            position: absolute;
            left: 0;
            width: 5px;
            height: 100%;
            background-color: var(--color-black);
          }
        }
      }
    }
  }
  .article{
    flex: 0 0 1300px;
    max-width: 1300px;
    @include s-1920{
      flex: 0 0 1000px;
      max-width: 1000px;
    }
    @include s-1440{
      flex: 0 0 800px;
      max-width: 800px;
    }
    @include s-1024{
      flex: 0 0 100%;
      max-width: 100%;
    }
    @include s-768{
      flex: 0 0 100%;
    }
    &-block{
      padding-top: 150px;
      @include s-1920{
        padding-top: 120px;
      }
      @include s-1440{
        padding-top: 90px;
      }
      @include s-1024{
        padding-top: 80px;
      }
      @include s-640{
        padding-top: 60px;
      }
      &:first-of-type{
        padding-top: 0;
      }
      .c-wrap{
        padding-left: 0;
        padding-right: 0;
      }
      .c-faq-item{
        &:last-of-type{
          border-bottom: 1px solid var(--color-grey-2);
        }
      }
      &-content{
        padding-top: 50px; 
        @include s-1920{
          padding-top: 42px;
        }
        @include s-1440{
          padding-top: 40px;
        }
        @include s-1024{
          padding-top: 30px;
        }
        @include s-640{
          padding-top: 25px;
        }
  
      }
    }
    &-title{

    }
    &-content{
      padding-top: 90px; 
      @include s-1920{
        padding-top: 80px;
      }
      @include s-1440{
        padding-top: 60px;
      }
      @include s-1024{
        padding-top: 50px;
      }
      @include s-640{
        padding-top: 40px;
      }

    }
  }
}

.p-faqs{

}
.p-terms{
  
}