.p-home {
  // background-color: var(--color-black-2);
  padding-top: 82px;
  @include s-1024 {
    padding-top: 62px;
  }
  @include s-1024 {
    padding-top: 46px;
  }

  .s {
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      margin: 0 auto;
      max-width: 2240px;
      @include s-1920 {
        max-width: 1706px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-1024 {
        max-width: 100%;
      }
      @include s-640 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  .s-hero {
    padding-top: 90px;
    &-wrap {
      padding-left: 120px;
      padding-right: 120px;
      display: flex;
      flex-wrap: nowrap;
      @include s-1920 {
        padding-left: 100px;
        padding-right: 100px;
      }
      @include s-1440 {
        padding-left: 80px;
        padding-right: 80px;
      }
      @include s-1024 {
        padding-left: 40px;
        padding-right: 40px;
      }
      @include s-768 {
        padding-right: 0;
      }
      @include s-640 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    &-left {
      flex: 1;
      padding-right: 190px;
      @include s-1920 {
        padding-right: 120px;
      }
      @include s-1440 {
        padding-right: 70px;
      }
      @include s-1024 {
        padding-right: 20px;
      }
      @include s-640 {
        max-width: 100%;
        padding-right: 0;
      }
      h1 {
        margin-top: 120px;
        position: relative;
        @include s-1920 {
          margin-top: 55px;
        }
        @include s-1440 {
          margin-top: 80px;
        }
        @include s-1024 {
          margin-top: 25px;
        }
        @include s-768 {
          margin-top: 60px;
        }
        @include s-640 {
          margin-top: 50px;
        }
      }
      .item {
        &-grad {
          padding-left: 20px;
          background: linear-gradient(90deg, #2f7e62 -34.83%, #969913 48.44%, #f9a01a 137.64%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          white-space: nowrap;
          &-icon {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 17px;
            @include s-1920 {
            }
            @include s-1440 {
            }
            @include s-1024 {
            }
            @include s-768 {
            }
            @include s-640 {
              bottom: -2px;
              height: 8px;
            }
            svg {
              width: 100%;
              height: 100%;
              line-height: 1;
              font-size: 0;
              margin: 0;
              padding: 0;
              display: block;
            }
          }
        }
        &-link {
          padding-top: 70px;
          max-width: 320px;
          @include s-1920 {
            max-width: 250px;
            padding-top: 50px;
          }
          @include s-1440 {
            max-width: 200px;
          }
          @include s-1024 {
            max-width: 190px;
          }
        }
        &-subtitle {
          padding-top: 30px;
          max-width: 872px;
          @include s-1920 {
            padding-top: 20px;
          }
        }
        &-products {
          padding-top: 145px;
          @include s-1920 {
            padding-top: 80px;
          }
          &-list {
            display: flex;
          }
          &-li {
            padding-right: 30px;
            @include s-1920 {
              padding-right: 25px;
            }
            @include s-1440 {
              padding-right: 10px;
            }
            @include s-1024 {
              padding-right: 14px;
            }
            &:last-of-type {
              padding-right: 0;
            }
          }
          &-wrap {
          }
          &-img {
            max-width: 290px;
            @include s-1920 {
              max-width: 250px;
            }
            @include s-1440 {
              max-width: 190px;
            }
            @include s-1024 {
              max-width: 140px;
            }
            @include s-768 {
              max-width: 215px;
            }
            @include s-640 {
              max-width: 150px;
            }
            img {
              width: 100%;
            }
          }
          &-title {
            padding-top: 8px;
          }
        }
      }
    }
    &-right {
      flex: 0 0 40%;
      max-width: 820px;
      @include s-1920 {
        flex: 0 0 35%;
        max-width: 590px;
      }
      @include s-640 {
        display: none;
      }
    }
    &-img {
      width: 100%;
      border-radius: 28% 0 0 0;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .s-services {
    padding-top: 300px;
    padding-bottom: 160px;
    @include s-1920 {
      padding-top: 265px;
    }
    @include s-1440 {
      padding-top: 200px;
    }
    @include s-1024 {
      padding-top: 170px;
      padding-bottom: 50px;
    }
    @include s-768 {
      padding-top: 150px;
    }
    @include s-640 {
      padding-top: 80px;
      padding-bottom: 30px;
    }
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include s-640 {
        flex-wrap: wrap;
      }
      h2 {
        position: relative;
        @include s-640 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        span {
          display: inline-flex;
          border: 2px solid var(--color-black);
          border-radius: 100%;
          border: 2px solid var(--color-black);
        }
      }
      &-desc {
        font-size: 24px;
        max-width: 470px;
        @include s-1920{
          max-width: 334px;
          font-size: 20px;
        }
        @include s-1440{
          max-width: 300px;
          font-size: 18px;
        }
        @include s-1024{
          max-width: 270px;
          font-size: 16px;
        }
        @include s-768 {
          max-width: 260px;
          font-size: 16px;
        }
        @include s-640 {
          padding-top: 15px;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    &-list {
      padding-top: 100px;
      @include s-1920 {
        padding-top: 60px;
      }
      @include s-640 {
        padding-top: 40px;
      }
      ul {
        margin: 0 -20px;
        display: flex;
        @include s-1920 {
          margin: 0 -14px;
        }
        @include s-1440 {
          margin: 0 -11px;
        }
        @include s-1024 {
          margin: 0 -6px;
        }
        @include s-768 {
          margin: 0;
          flex-wrap: wrap;
        }
        @include s-640 {
          margin: 0 -6px;
          flex-wrap: nowrap;
          overflow: auto;
        }
      }
      li {
        padding-left: 20px;
        padding-right: 20px;
        flex: 0 0 25%;
        max-width: 25%;
        @include s-1920 {
          padding-left: 14px;
          padding-right: 14px;
        }
        @include s-1440 {
          padding-left: 11px;
          padding-right: 11px;
        }
        @include s-1024 {
          padding-left: 6px;
          padding-right: 6px;
        }
        @include s-768 {
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 20px;
        }
        @include s-640 {
          flex: 0 0 auto;
          max-width: 260px;
          padding-left: 6px;
          padding-right: 6px;
        }
      }
      .item {
        padding: 80px 60px 60px;
        height: 742px;
        position: relative;
        @include s-1920 {
          padding: 35px;
          height: 588px;
        }
        @include s-1440 {
          padding: 40px 25px 25px;
          height: 440px;
        }
        @include s-1024 {
          padding: 30px 20px 20px;
          height: 390px;
        }
        @include s-768 {
          padding: 30px 20px 20px;
          height: 465px;
        }
        @include s-640 {
          height: 380px;
        }
        &-icon {
          position: relative;
          z-index: 1;
          height: 180px;
          width: 180px;
          @include s-1920 {
            height: 130px;
            width: 130px;
          }
          @include s-1440 {
            height: 110px;
            width: 110px;
          }
          @include s-1024 {
            height: 90px;
            width: 90px;
          }
          @include s-768 {
            height: 120px;
            width: 120px;
          }
          @include s-640 {
            height: 90px;
            width: 90px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        &-text {
          padding-top: 80px;
          position: relative;
          z-index: 1;
          color: var(--color-white);
          @include s-1920 {
            padding-top: 70px;
          }
          @include s-1440 {
            padding-top: 35px;
          }
          @include s-1024 {
            padding-top: 25px;
          }
          @include s-768 {
            padding-top: 40px;
          }
          @include s-640 {
            padding-top: 25px;
          }
          h5 {
            font-size: 42px;
            @include s-1920 {
              font-size: 38px;
            }
            @include s-1440 {
              font-size: 28px;
            }
            @include s-1024 {
              font-size: 24px;
            }
            @include s-640 {
              font-size: 20px;
            }
          }
        }
        &-desc {
          padding-top: 30px;
          color: var(--color-grey-2);
          font-size: 20px;
          line-height: 145%;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          @include s-1920 {
            padding-top: 20px;
            font-size: 18px;
          }
          @include s-1440 {
            padding-top: 15px;
            font-size: 16px;
          }
          @include s-1024 {
            font-size: 14px;
          }
        }
        &-link {
          padding-top: 93px;
          position: relative;
          z-index: 1;
          @include s-1920 {
            padding-top: 60px;
          }
          @include s-1440 {
            padding-top: 48px;
          }
          @include s-1024 {
            padding-top: 45px;
          }
          @include s-768 {
            padding-top: 55px;
          }
          @include s-640 {
            padding-top: 40px;
          }
          .btn {
            max-width: 100%;
          }
        }
        &-bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .s-steps {
    &-top {
      display: flex;
      flex-wrap: nowrap;
      height: 1630px;
      background: linear-gradient(180deg, #ffffff 4.09%, #d9a122 46.83%, #a0971f 62.74%, #608c40 79.87%, #f6f6f6 102.19%);
      @include s-1920 {
        height: 1342px;
      }
      @include s-1440 {
        height: 1130px;
      }
      @include s-1024 {
        height: 860px;
      }
      @include s-768 {
        height: 900px;
      }
      @include s-640 {
        height: auto;
        flex-wrap: wrap;
      }
    }
    &-img {
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      align-items: center;
      @include s-640 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item {
        width: 100%;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    &-desc {
      flex: 0 0 50%;
      max-width: 50%;
      @include s-640 {
        flex: 0 0 100%;
        max-width: 100%;
        order: -1;
        padding-left: 30px;
        padding-right: 30px;
      }
      &--title {
      }
      .item {
        padding-top: 400px;
        max-width: 725px;
        @include s-1920 {
          padding-top: 350px;
          max-width: 588px;
        }
        @include s-1440 {
          padding-top: 300px;
          max-width: 428px;
        }
        @include s-1024 {
          padding-top: 180px;
          max-width: 330px;
        }
        @include s-768 {
          padding-top: 240px;
        }
        @include s-640 {
          padding-top: 80px;
        }
      }

      ul {
        padding-top: 105px;
        @include s-1920 {
          padding-top: 90px;
        }
        @include s-1440 {
          padding-top: 65px;
        }
        @include s-1024 {
          padding-top: 55px;
        }
        @include s-768 {
          padding-top: 55px;
        }
        @include s-640 {
          padding-top: 60px;
        }
      }
      li {
        padding-top: 70px;
        @include s-1920 {
          padding-top: 55px;
        }
        @include s-1440 {
          padding-top: 40px;
        }
        @include s-1024 {
          padding-top: 30px;
        }
        @include s-768 {
        }
        @include s-640 {
          display: flex;
          align-items: center;
        }
        &:first-of-type {
          padding-top: 0;
        }
        .icon {
          border-radius: 50%;
          border: 2px solid var(--color-white);
          color: var(--color-white);
          text-transform: uppercase;
          font-family: var(--font-bold-tele);
          font-size: 16px;
          padding: 7px 17px;
          line-height: 150%;
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 110px;
          width: 100%;
          @include s-1920 {
            font-size: 14px;
            max-width: 95px;
          }
          @include s-1440 {
            font-size: 12px;
            max-width: 84px;
          }
          @include s-1024 {
            font-size: 11px;
            max-width: 80px;
          }
          @include s-640 {
            max-width: 70px;
            flex: 0 0 70px;
            height: 30px;
            padding: 0;
          }
        }
        .text {
          padding-top: 35px;
          font-size: 34px;
          line-height: 140%;
          @include s-1920 {
            padding-top: 20px;
            font-size: 24px;
          }
          @include s-1440 {
            font-size: 20px;
          }
          @include s-1024 {
            padding-top: 18px;
            font-size: 18px;
          }
          @include s-768 {
          }
          @include s-640 {
            font-size: 16px;
            padding-left: 25px;
          }
        }
      }
    }
    &-bottom {
      background-color: var(--color-white);
      padding-top: 60px;
      padding-bottom: 60px;
      position: relative;
      @include s-1920 {
        padding-top: 45px;
        padding-bottom: 45px;
      }
      @include s-1440 {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      @include s-1024 {
        padding-top: 32px;
        padding-bottom: 32px;
      }
      @include s-768 {
        padding-top: 27px;
        padding-bottom: 27px;
      }
      @include s-640 {
        padding-top: 25px;
        padding-bottom: 5px;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(84.42deg, #2f7e62 -9.76%, #969913 66.62%, #f9a01a 117.96%);
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(84.42deg, #2f7e62 -9.76%, #969913 66.62%, #f9a01a 117.96%);
      }
      &-wrap {
        padding-left: 40px;
        padding-right: 40px;
        margin: 0 auto;
        max-width: 2240px;
        @include s-1920 {
          max-width: 1706px;
        }
        @include s-1440 {
          max-width: 1360px;
        }
        @include s-1024 {
          max-width: 100%;
        }
        @include s-640 {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
        @include s-640 {
          flex-wrap: wrap;
        }
      }
      li {
        flex: 0 0 25%;
        max-width: 25%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include s-640 {
          flex: 0 0 50%;
          max-width: 50%;
          padding-bottom: 20px;
        }
        .icon {
          width: 32px;
          height: 32px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .text {
          padding-left: 20px;
          text-transform: uppercase;
          font-family: var(--font-bold-tele);
          font-size: 18px;
          @include s-1920 {
            font-size: 16px;
          }
          @include s-1440 {
            font-size: 14px;
          }
          @include s-1024 {
            font-size: 12px;
          }
          @include s-768 {
            font-size: 11px;
          }
          @include s-640 {
          }
        }
      }
    }
  }

  .s-shop {
    padding-top: 300px;
    padding-bottom: 310px;
    overflow: hidden;
    @include s-1920{
      padding-top: 250px;
      padding-bottom: 230px; 
    }
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include s-640 {
        flex-wrap: wrap;
      }
      h2 {
        position: relative;
        span {
          display: inline-flex;
          border: 2px solid var(--color-black);
          border-radius: 100%;
          border: 2px solid var(--color-black);
        }
      }
      &-desc {
        font-size: 24px;
        max-width: 502px;
        @include s-1920{
          max-width: 334px;
          font-size: 20px;
        }
        @include s-1440{
          max-width: 300px;
          font-size: 18px;
        }
        @include s-1024{
          max-width: 270px;
          font-size: 16px;
        }
        @include s-768 {
          max-width: 260px;
          font-size: 16px;
        }
        @include s-640 {
          padding-top: 15px;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    &-slider {
      padding-top: 100px;

      & .swiper-wrapper {
        display: flex;
        flex-wrap: nowrap;
      }

      & .swiper-slide {
        flex: 0 0 686px;
        max-width: 686px;
        height: 962px;
        margin-right: 50px;
       
        @include s-1920 {
          height: 720px;
          max-width: 508px;
          flex: 0 0 508px;
          margin-right: 48px;
        }
        @include s-1440 {
          height: 582px;
          max-width: 408px;
          flex: 0 0 408px;
          margin-right: 20px;
        }
        @include s-1024 {
          height: 474px;
          max-width: 292px;
          flex: 0 0 292px;
          margin-right: 18px;
        }
        @include s-768 {
          height: 478px;
          max-width: 326px;
          flex: 0 0 326px;
        }
        @include s-640 {
          height: 458px;
          max-width: 315px;
          flex: 0 0 315px;
          margin-right: 15px;
        }
      }
    }
  }

  .s-health {
    position: relative;
    padding-top: 220px;
    padding-bottom: 220px;
    min-height: 1260px;
    @include s-1920 {
      padding-top: 165px;
      padding-bottom: 165px;
      min-height: 945px;
    }
    @include s-1440 {
      padding-top: 110px;
      padding-bottom: 110px;
      min-height: 733px;
    }
    @include s-1024 {
      min-height: 750px;
    }
    @include s-768 {
      padding-top: 165px;
      padding-bottom: 165px;
      min-height: 974px;
    }
    @include s-640 {
      padding-top: 100px;
      padding-bottom: 100px;
      min-height: 700px;
    }
    .item {
      position: relative;
      z-index: 1;
      text-align: center;
      color: var(--color-white);
      padding-left: 80px;
      padding-right: 80px;
      max-width: 2050px;
      margin: 0 auto;
      @include s-1024{
        padding-left: 52px;
        padding-right: 52px;
      }
      @include s-768{
        padding-left: 40px;
        padding-right: 40px;
      }
      @include s-640{
        padding-left: 15px;
        padding-right: 15px;
      }
      &-icon {
        width: 130px;
        height: 110px;
        margin: 0 auto;
        @include s-1920{
          width: 84px;
          height: 70px;
        }

        @include s-640{
          width: 60px;
          height: 50px;
        }
         img{
          width: 100%;
          height: 100%;
         }
      }
      &-title {
        padding-top: 120px;
        color: var(--color-white);
        font-size: 72px;
        font-family: var(--font-regular-tele);
        line-height: 120%;
        @include s-1920 {
          padding-top: 80px;
        }
        @include s-1440 {
          font-size: 58px;
          padding-top: 60px;
        }
        @include s-1024 {
          font-size: 48px;
        }
        @include s-768 {
          padding-top: 90px;
        }
        @include s-640 {
          font-size: 30px;
          padding-top: 55px;
        }
      }
      &-subtitle {
        padding-top: 90px;
        color: var(--color-white);
        font-size: 34px;
        line-height: 145%;
        @include s-1920 {
          padding-top: 60px;
          font-size: 24px;
        }
        @include s-1440 {
          padding-top: 50px;
          font-size: 20px;
        }
        @include s-1024 {
          font-size: 18px;
        }
        @include s-768 {
          padding-top: 70px;
        }
        @include s-640 {
          padding-top: 50px;
          font-size: 14px;
        }
      }
      &-link {
        padding-top: 70px;
        max-width: 320px;
        margin: 0 auto;
        @include s-1920 {
          max-width: 250px;
          padding-top: 45px;
        }
        @include s-1440 {
          max-width: 200px;
          padding-top: 40px;
        }
        @include s-1024 {
          max-width: 190px;
        }
        @include s-768 {
          padding-top: 50px;
        }
        @include s-640 {
          padding-top: 35px;
        }
      }
      &-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .s-join {
    padding-top: 300px;
    padding-bottom: 300px;
    overflow: hidden;
    @include s-1920 {
      padding-top: 165px;
      padding-bottom: 165px;
    }
    @include s-1440 {
      padding-top: 110px;
      padding-bottom: 110px;
    }
    @include s-1024 {
    }
    @include s-768 {
      padding-top: 165px;
      padding-bottom: 165px;
    }
    @include s-640 {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include s-640 {
        flex-wrap: wrap;
      }
      h2 {
        position: relative;
        span {
          display: inline-flex;
          border: 2px solid var(--color-black);
          border-radius: 100%;
          border: 2px solid var(--color-black);
        }
      }
      &-desc {
        font-size: 24px;
        max-width: 502px;
        @include s-1920{
          max-width: 334px;
          font-size: 20px;
        }
        @include s-1440{
          max-width: 300px;
          font-size: 18px;
        }
        @include s-1024{
          max-width: 270px;
          font-size: 16px;
        }
        @include s-768 {
          max-width: 260px;
          font-size: 16px;
        }
        @include s-640 {
          max-width: 100%;
          font-size: 16px;
        }
      }
    }
    &-slider {
      padding-top: 95px;
      @include s-1920 {

      }
      @include s-1440 {
        padding-top: 70px;
      }
      @include s-1024 {
        padding-top: 60px;
      }
      @include s-768 {
        padding-top: 60px;
      }
      @include s-640 {
        padding-top: 50px;
      }
      & .swiper-wrapper {
        display: flex;
        flex-wrap: nowrap;
      }
      & .swiper-slide {
        flex: 0 0 686px;
        max-width: 686px;
        height: 962px;
        margin-right: 50px;
        @include s-1920 {
          height: 720px;
          max-width: 582px;
          flex: 0 0 582px;
          margin-right: 48px;
        }
        @include s-1440 {
          height: 582px;
          max-width: 408px;
          flex: 0 0 408px;
          margin-right: 20px;
        }
        @include s-1024 {
          height: 444px;
          max-width: 292px;
          flex: 0 0 292px;
          margin-right: 18px;
        }
        @include s-768 {
          // height: 582px;
        }
        @include s-640 {
          height: 420px;
          max-width: 240px;
          flex: 0 0 240px;
          margin-right: 15px;
        }
      }
      &-item {
        height: 100%;
      }
      .item {
        border-radius: 5px;
        overflow: hidden;
        border: 1.5px solid var(--color-grey);
        height: 100%;
        background-color: var(--color-black);
        &-white {
          height: 100%;
          background-color: var(--color-white);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          // padding: 230px 150px;
          // @include s-1920 {
          //   padding: 155px 110px;
          // }
          @include s-640 {
            padding: 0 0 0 50px;
          }
          &-wrap{
            max-width: 420px;
            @include s-640{
              max-width: 210px;
            }
          }
          .item {
            &-title {
              font-size: 42px;
              font-family: var(--font-regular-tele);
              @include s-1920{
                font-size: 34px; 
              }
              @include s-1440{
                font-size: 28px; 
              }
              @include s-1024{
                font-size: 24px; 
              }
              @include s-640{
                font-size: 20px; 
              }
            }

            &-ul {
              padding-top: 80px;
              @include s-1920{
                padding-top: 56px;
              }
              @include s-1440{
                padding-top: 45px;
              }
              @include s-768{
                padding-top: 30px;
              }
              @include s-640{
                padding-top: 42px;
              }
            }

            &-li {
              padding-top: 20px;
              display: flex;
              align-items: flex-start;
              font-size: 20px;
              @include s-1920{
                padding-top: 14px;
                font-size: 18px;
              }
              @include s-1440{
                padding-top: 10px;
                font-size: 16px; 
              }
              @include s-1440{
                padding-top: 6px;
                font-size: 14px;
              }
              @include s-768{
                padding-top: 6px;
              }
              @include s-640{
                padding-top: 15px;
              }
              &:first-of-type { 
                padding-top: 0;
              }
              &-icon {
                position: relative;
                width: 28px;
                height: 28px;
                display: block;

                @include s-1920 {
                  width: 17.5px;
                  height: 17.5px;
                }

                @include s-1440 {
                  width: 18px;
                  height: 18px;
                }

                @include s-1024 {
                  width: 15px;
                  height: 15px;
                }

                svg {
                  width: 100%;
                  height: 100%;
                }

                &-hidden {
                  position: absolute;
                  left: 50%;
                  transform: translate(-50%, -100%);
                  transform-origin: center;
                  top: 0;
                  opacity: 0;
                  pointer-events: none;
                  user-select: none;
                }

                &:hover {
                  & .item-li-icon-hidden {
                    opacity: 1;
                    pointer-events: auto;
                  }
                }
              }
              &-title {
                padding-left: 15px;
                display: block;
                @include s-1920 {
                  padding-left: 13px;
                }
                @include s-1440 {
                  padding-left: 10px;
                }
                @include s-1024 {
                }
                @include s-1920 {
                }
              }
            }

            &-link {
              padding-top: 100px;
              @include s-1920 {
                padding-top: 70px;
              }
              @include s-1440 {
                padding-top: 60px;
              }
              @include s-1024 {
                padding-top: 50px;
              }
              @include s-640 {
                padding-top: 30px;
              }

            }
          }
        }
        &-img {
          height: 568px;
          overflow: hidden;
          @include s-1920 {
            height: 420px;
          }
          @include s-1440 {
            height: 320px;
          }
          @include s-1024 {
            height: 240px;
          }
          @include s-768 {
            height: 245px;
          }
          @include s-640 {
            height: 200px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &-content {
          // background-color: var(--color-black);
            padding: 65px 70px;
            color: var(--color-white);
          @include s-1920 {
            padding: 45px 50px 40px;
          }
          @include s-1440 {
            padding: 35px 30px 30px;
          }
          @include s-1024 {
            padding: 25px;
          }
          @include s-768 {

          }
          @include s-640 {
            padding: 25px 20px;
          }
        }
        &-title {
          h5 {
            color: var(--color-white);
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &-desc {
          padding-top: 20px;
          span {
            color: var(--color-grey-2);
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &-link {
          padding-top: 105px;
          @include s-1920 {
            padding-top: 70px;
          }
          @include s-1440 {
            padding-top: 63px;
          }
          @include s-1024 {
            padding-top: 46px;
          }
          a {
          }
        }
      }
    }
  }

  .s-coach {
    overflow: hidden;
    &-wrap {
      display: flex;
      @include s-768 {
        flex-wrap: wrap;
      }
    }
    &-img {
      flex: 0 0 50%;
      max-width: 50%;
      @include s-768 {
        flex: 0 0 100%;
        max-width: 100%;
        height: 468px;
      }
      @include s-640{
        height: 320px;
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
    &-desc {
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 220px;
      // padding-right: 220px;
      @include s-1920{
        padding-left: 180px;
      }
      @include s-1440{
        padding-left: 60px;
      }
      @include s-1024{
        padding-left: 40px;
      } 
      @include s-768 {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 70px;
        padding-bottom: 70px;
      }
      .item {
        position: relative;
        z-index: 1;
        color: var(--color-white);
        max-width: 860px;
        @include s-1920{
          max-width: 470px;
        }
        @include s-1440{
          max-width: 560px;
        }
        @include s-1024 {
          max-width: 432px;
          margin: 0 auto;
        }
        &-logo {
        }
        &-title {
          padding-top: 120px;
          @include s-1920 {
            padding-top: 70px;
          }
          @include s-1440 {
            padding-top: 60px;
          }
          @include s-1024 {
            padding-top: 40px;
          }
          @include s-640 {
            padding-top: 18px;
          }
          h2 {
            color: var(--color-white);
          }
        }

        &-desc {
          padding-top: 20px;
          color: var(--color-grey-2);
          @include s-1024 {
            padding-top: 14px;
          }
        }

        &-list {
          padding-top: 60px;
          display: flex;
          @include s-1024 {
            padding-top: 40px;
          }
          li {
            padding-left: 35px;
            padding-right: 50px;
            position: relative;
            @include s-1920 {
              padding-left: 20px;
              padding-right: 30px;
            }
            @include s-1440 {
              padding-left: 15px;
              padding-right: 23px;
            }
            @include s-768 {
              padding-right: 21px;
            }
            @include s-640 {
            }
            &:first-of-type {
              padding-left: 0;
            }
            &:last-of-type {
              padding-right: 0;
              &::after {
                display: none;
              }
            }
            &::after {
              content: "";
              width: 15px;
              height: 15px;
              background-color: var(--color-green-1);
              border-radius: 100%;
              position: absolute;
              top: 10px;
              right: 0;
              display: inline-flex;
              @include s-1920 {
                width: 10px;
                height: 10px;
              }
              @include s-1440 {
                width: 8px;
                height: 8px;
              }
              @include s-1024 {
                width: 6px;
                height: 6px;
              }
            }
          }
          a {
            text-transform: uppercase;
            font-size: 18px;
            line-height: 150%;
            font-family: var(--font-bold-tele);
            color: var(--color-white);
            @include s-1920 {
              font-size: 16px;
            }
            @include s-1440 {
              font-size: 14px;
            }
            @include s-1024 {
              font-size: 11px;
            }
            @include s-640 {
            }
          }
        }
        &-link {
          padding-top: 120px;
          max-width: 320px;
          @include s-1920 {
            max-width: 250px;
            padding-top: 80px;
          }
          @include s-1440 {
            max-width: 200px;
            padding-top: 70px;
          }
          @include s-1024 {
            max-width: 190px;
            padding-top: 50px;
          }
          @include s-640 {
            padding-top: 45px;
          }
        }
      }
      &-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .s-faqs {
    padding-top: 295px;
    padding-bottom: 250px;
    @include s-1920 {
      padding-top: 250px;
      padding-bottom: 200px;
    }
    @include s-1440 {
      padding-top: 180px;
      padding-bottom: 160px;
    }
    @include s-1024 {
      padding-top: 150px;
      padding-bottom: 120px;
    }
    @include s-768 {
    }
    @include s-640 {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  .s-partners {
    padding-top: 245px;
    border-top: 1px solid var(--color-grey-2);
    @include s-1920 {
      padding-top: 200px;
    }
    @include s-1440 {
      padding-top: 160px;
    }
    @include s-1024 {
      padding-top: 120px;
    }
    @include s-768 {
    }
    @include s-640 {
      padding-top: 100px;
    }
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include s-640{
        flex-wrap: wrap;
      }
      h2 {
        position: relative;
        span {
          display: inline-flex;
          border: 2px solid var(--color-black);
          border-radius: 100%;
          border: 2px solid var(--color-black);
        }
      }
      &-desc {
        font-size: 24px;
        max-width: 502px;
        @include s-1920{
          max-width: 334px;
          font-size: 20px;
        }
        @include s-1440{
          max-width: 300px;
          font-size: 18px;
        }
        @include s-1024{
          max-width: 270px;
          font-size: 16px;
        }
        @include s-768 {
          max-width: 260px;
          font-size: 16px;
        }
        @include s-640 {
          max-width: 100%;
          font-size: 16px;
        }
      }
    }
    &-content {
      padding-top: 135px;
      @include s-1920 {
        padding-top: 120px;
      }
      @include s-1440 {
        padding-top: 80px;
      }
      @include s-1024 {
        padding-top: 60px;
      }
      @include s-768 {
        padding-top: 80px;
      }
      @include s-640 {
        padding-top: 6 0px;
      }
    }
    &-list {
      display: flex;
      align-items: center;
      @include s-768 {
        margin: 0 -18px;
        flex-wrap: wrap;
      }
    }
    &-li {
      padding-left: 53px;
      padding-right: 53px;
      display: inline-flex;
      align-items: center;
      @include s-1920 {
        padding-left: 40px;
        padding-right: 40px;
      }
      @include s-1440 {
      }
      @include s-1024 {
        padding-left: 18px;
        padding-right: 18px;
      }
      @include s-768 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding-bottom: 50px;
      }
      @include s-640 {
      }
      &:first-of-type {
        padding-left: 0;
        @include s-768 {
          padding-left: 18px;
        }
      }
      &:last-of-type {
        padding-right: 0;
        @include s-768 {
          padding-right: 18px;
        }
      }
      .item {
      }
      img {
        max-width: 100%;
      }
    }
  }
  .c-start {
    padding: 300px 50px 50px;
    @include s-1920 {
      padding-top: 200px;
    }
    @include s-1440 {
      padding: 180px 40px 40px;
    }
    @include s-1024 {
      padding: 100px 40px 40px;
    }
    @include s-640 {
      padding: 50px 15px 40px;
    }
  }
}
