.p-services {
  padding-top: 82px;
  @include s-1024 {
    padding-top: 62px;
  }
  @include s-1024 {
    padding-top: 46px;
  }

  .s {
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      margin: 0 auto;
      max-width: 2240px;
      @include s-1920 {
        max-width: 1706px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-1024 {
        max-width: 100%;
      }
      @include s-640 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  & .swiper-wrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  & .swiper-slide {
    flex: 0 0 686px;
    max-width: 686px;
    height: 962px;
    margin-right: 50px !important;
    @include s-1920 {
      height: 720px;
      max-width: 508px;
      flex: 0 0 508px;
      margin-right: 48px !important;
    }
    @include s-1440 {
      height: 582px;
      max-width: 408px;
      flex: 0 0 408px;
      margin-right: 20px !important;
    }
    @include s-1024 {
      height: 474px;
      max-width: 292px;
      flex: 0 0 292px;
      margin-right: 18px !important;
    }
    @include s-768 {
      height: 478px;
      max-width: 326px;
      flex: 0 0 326px;
    }
    @include s-640 {
      height: 458px;
      max-width: 315px;
      flex: 0 0 315px;
      margin-right: 15px !important;
    }
  }

  .s-hero {
    position: relative;
    height: 1080px;
    @include s-1920 {
      height: 750px;
    }
    @include s-1440 {
      height: 550px;
    }
    @include s-1024 {
      height: 500px;
    }
    @include s-640 {
      height: 510px;
    }
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      z-index: 1;
      max-width: 2240px;
      margin: 0 auto;
      height: 100%;
      @include s-1920 {
        max-width: 1700px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-1024 {
        max-width: 100%;
      }
      @include s-640 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    &-desc {
      flex: 0 0 50%;
      max-width: 50%;
      flex: 1;
      // padding-right: 190px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include s-768 {
        //   padding-right: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item {
        max-width: 1080px;
        @include s-1920 {
          max-width: 602px;
        }
        @include s-1440 {
          max-width: 570px;
        }
        @include s-1024 {
          max-width: 517px;
        }
        @include s-768 {
          max-width: 508px;
        }
        @include s-640 {
          max-width: 100%;
        }
        h1 {
          color: var(--color-white);
        }
        &-desc {
          padding-top: 50px;
          max-width: 580px;
          color: var(--color-white);
          font-size: 24px;
          line-height: 145%;
          @include s-1920 {
            max-width: 480px;
            font-size: 20px;
            padding-top: 40px;
          }
          @include s-1440 {
            max-width: 380px;
            font-size: 18px;
            padding-top: 30px;
          }
          @include s-1024 {
            font-size: 14px;
            padding-top: 25px;
          }
          @include s-640 {
            padding-top: 15px;
            max-width: 100%;
          }
        }
        &-link {
          padding-top: 70px;
          max-width: 320px;
          @include s-1920 {
            max-width: 250px;
            padding-top: 50px;
          }
          @include s-1440 {
            max-width: 200px;
          }
          @include s-1024 {
            max-width: 190px;
          }
        }
      }
    }
    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
        display: none;
        @include s-768 {
          display: block;
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: right;
      }
    }
  }

  .s-anchors {
    padding-top: 90px;
    @include s-1920 {
      padding-top: 60px;
    }
    @include s-1440 {
      padding-top: 50px;
    }
    @include s-1024 {
      padding-top: 40px;
    }
    @include s-768 {
    }
    @include s-640 {
      padding-top: 20px;
    }
    ul {
      display: flex;
    }
    li {
      padding-right: 40px;
      &:last-of-type {
        padding-right: 0;
      }
    }

    .btn {
      &:hover {
        .btn-icon {
          svg {
            path {
              stroke: var(--color-white) !important;
            }
            circle {
              stroke: var(--color-white) !important;
            }
          }
        }
      }
      &-wrap {
        padding: 25px 45px 24px;
      }
      &-text {
        font-size: 24px;
        padding-right: 25px;
        @include s-1920 {
          font-size: 20px;
          padding-right: 15px;
        }
        @include s-1440 {
          font-size: 18px;
        }
        @include s-1024 {
          font-size: 16px;
        }
        @include s-640 {
          padding-right: 8px;
        }
      }
      &-icon {
        width: 42px;
        height: 42px;
        display: block;
        svg {
          margin-right: 0;
          width: 100%;
          height: 100%;
          path {
            stroke: var(--color-black) !important;
          }
          circle {
            stroke: var(--color-black) !important;
          }
        }
      }
    }
  }

  .s-before {
    padding-top: 220px;
    .s-wrap {
      display: flex;
    }
    &-img {
      flex: 0 0 50%;
      max-width: 50%;
      @include s-768 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item-img {
        width: 100%;
        height: 830px;
        @include s-1920 {
          height: 630px;
        }
        @include s-1440 {
          height: 470px;
        }
        @include s-1024 {
          height: 350px;
        }
        @include s-768 {
          height: 410px;
        }
        @include s-640 {
          height: 280px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-desc {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 120px;
      @include s-768 {
        padding-left: 0;
        padding-top: 60px;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item {
        max-width: 870px;
        @include s-1920 {
          max-width: 650px;
        }
        @include s-1440 {
          max-width: 520px;
        }
        @include s-1024 {
          max-width: 400px;
        }
        @include s-768 {
          max-width: 500px;
          margin: 0 auto;
        }
        @include s-640 {
          max-width: 100%;
          padding-left: 15px;
          padding-right: 15px;
        }
        h2 {
        }
        &-desc {
          padding-top: 45px;
          @include s-1024 {
            padding-top: 30px;
          }
          @include s-640 {
            padding-top: 20px;
          }
        }
        &-link {
          padding-top: 60px;
        }
      }
    }
  }

  .s-product {
    overflow: hidden;
    &-box {
      padding-top: 180px;
      padding-bottom: 180px;
      border-bottom: 1px solid var(--color-grey-2);
      &-top {
      }
      &-wrap {
        display: flex;
      }
      &-img {
        flex: 0 0 50%;
        max-width: 50%;
        @include s-768 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .item-img {
          width: 100%;
          height: 830px;
          @include s-1920 {
            height: 630px;
          }
          @include s-1440 {
            height: 470px;
          }
          @include s-1024 {
            height: 350px;
          }
          @include s-768 {
            height: 410px;
          }
          @include s-640 {
            height: 280px;
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-desc {
        flex: 0 0 50%;
        max-width: 50%;
        @include s-768 {
          padding-top: 60px;
          flex: 0 0 100%;
          max-width: 100%;
        }
        .item {
          max-width: 870px;
          @include s-1920 {
            max-width: 650px;
          }
          @include s-1440 {
            max-width: 520px;
          }
          @include s-1024 {
            max-width: 400px;
          }
          @include s-768 {
            max-width: 500px;
            margin: 0 auto;
          }
          @include s-640 {
            max-width: 100%;
            padding-left: 15px;
            padding-right: 15px;
          }
          h2 {
          }
          &-desc {
            padding-top: 45px;
            @include s-1024 {
              padding-top: 30px;
            }
            @include s-640 {
              padding-top: 20px;
            }
          }
          &-link {
            padding-top: 60px;
          }
        }
      }
      &-slider {
        padding-top: 80px;
        &-top {
        }
        &-content {
          padding-top: 50px;
          @include s-1920 {
            padding-top: 40px;
          }
          @include s-1440 {
            padding-top: 30px;
          }
          @include s-1024 {
            padding-top: 15px;
          }
          .swiper-slide {
            .item {
              padding: 170px 60px 60px;
              height: 100%;
              position: relative;
              @include s-1920 {
                padding: 130px 60px 60px;
              }
              @include s-1440 {
                padding: 110px 50px 50px;
              }
              @include s-1024 {
                padding: 50px;
              }
              @include s-768 {
                padding: 80px 25px 25px;
              }
              @include s-640 {
              }
              &-icon {
                position: relative;
                z-index: 1;
                height: 180px;
                width: 180px;
                margin: 0 auto;
                @include s-1920 {
                  height: 130px;
                  width: 130px;
                }
                @include s-1440 {
                  height: 110px;
                  width: 110px;
                }
                @include s-1024 {
                  height: 90px;
                  width: 90px;
                }
                @include s-768 {
                  height: 120px;
                  width: 120px;
                }
                @include s-640 {
                  height: 90px;
                  width: 90px;
                }
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              &-text {
                padding-top: 80px;
                position: relative;
                z-index: 1;
                color: var(--color-white);
                @include s-1920 {
                  padding-top: 70px;
                }
                @include s-1440 {
                  padding-top: 35px;
                }
                @include s-1024 {
                  padding-top: 25px;
                }
                @include s-768 {
                  padding-top: 40px;
                }
                @include s-640 {
                  padding-top: 25px;
                }
                h5 {
                  font-size: 42px;
                  @include s-1920 {
                    font-size: 38px;
                  }
                  @include s-1440 {
                    font-size: 28px;
                  }
                  @include s-1024 {
                    font-size: 24px;
                  }
                  @include s-640 {
                    font-size: 20px;
                  }
                }
              }
              &-desc {
                padding-top: 110px;
                color: var(--color-white);
                font-size: 42px;
                line-height: 145%;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                @include s-1920 {
                  padding-top: 90px;
                  font-size: 34px;
                }
                @include s-1440 {
                  padding-top: 60px;
                  font-size: 28px;
                }
                @include s-1024 {
                  padding-top: 34px;
                  font-size: 24px;
                }
                @include s-768 {
                  padding-top: 45px;
                }
              }
              &-link {
                padding-top: 93px;
                position: relative;
                z-index: 1;
                display: flex;
                justify-content: center;
                @include s-1920 {
                  padding-top: 60px;
                }
                @include s-1440 {
                  padding-top: 48px;
                }
                @include s-1024 {
                  padding-top: 45px;
                }
                @include s-768 {
                  padding-top: 55px;
                }
                @include s-640 {
                  padding-top: 40px;
                }
                .btn {
                  // max-width: 100%;
                  // margin: 0 auto;
                }
              }
              &-bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .c-product {
          }
        }
      }
    }
  }

  .s-after {
    padding-top: 220px;
    .s-wrap {
      display: flex;
    }
    &-img {
      flex: 0 0 50%;
      max-width: 50%;
      @include s-768 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item-img {
        width: 100%;
        height: 830px;
        @include s-1920 {
          height: 630px;
        }
        @include s-1440 {
          height: 470px;
        }
        @include s-1024 {
          height: 350px;
        }
        @include s-768 {
          height: 410px;
        }
        @include s-640 {
          height: 280px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-desc {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 120px;
      @include s-768 {
        padding-left: 0;
        padding-top: 60px;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item {
        max-width: 870px;
        @include s-1920 {
          max-width: 650px;
        }
        @include s-1440 {
          max-width: 520px;
        }
        @include s-1024 {
          max-width: 400px;
        }
        @include s-768 {
          max-width: 500px;
          margin: 0 auto;
        }
        @include s-640 {
          max-width: 100%;
          padding-left: 15px;
          padding-right: 15px;
        }
        h2 {
        }
        &-desc {
          padding-top: 45px;
          @include s-1024 {
            padding-top: 30px;
          }
          @include s-640 {
            padding-top: 20px;
          }
        }
        &-link {
          padding-top: 60px;
        }
      }
    }
  }

  .s-works {
  }
  .s-faqs {
  }
  .c-start {
    padding: 300px 50px 50px;
    @include s-1920 {
      padding-top: 200px;
    }
    @include s-1440 {
      padding: 180px 40px 40px;
    }
    @include s-1024 {
      padding: 100px 40px 40px;
    }
    @include s-640 {
      padding: 100px 15px 40px;
    }
    .c-start {
      &-item {
        max-width: 1440px;
        margin: 0 auto;
        @include s-1920 {
          max-width: 1024px;
        }
        @include s-1440 {
          max-width: 900px;
        }
        @include s-1024 {
          max-width: 620px;
        }
        @include s-768 {
          max-width: 500px;
        }
      }
    }
  }
}
