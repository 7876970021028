.p-works {
  padding-top: 82px;
  @include s-1024 {
    padding-top: 62px;
  }
  @include s-1024 {
    padding-top: 46px;
  }

  .s-hero {
    position: relative;
    height: 1080px;
    @include s-1920 {
      height: 750px;
    }
    @include s-1440 {
      height: 550px;
    }
    @include s-1024 {
      height: 500px;
    }
    @include s-640 {
      height: 510px;
    }
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      z-index: 1;
      max-width: 2240px;
      margin: 0 auto;
      height: 100%;
      @include s-1920 {
        max-width: 1700px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-1024 {
        max-width: 100%;
      }
      @include s-640 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    &-desc {
      flex: 0 0 50%;
      max-width: 50%;
      flex: 1;
      // padding-right: 190px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include s-768 {
        //   padding-right: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item {
        max-width: 1080px;
        @include s-1920 {
          max-width: 602px;
        }
        @include s-1440 {
          max-width: 570px;
        }
        @include s-1024 {
          max-width: 517px;
        }
        @include s-768 {
          max-width: 508px;
        }
        @include s-640 {
          max-width: 100%;
        }
        h1 {
          color: var(--color-white);
        }
        &-desc {
          padding-top: 50px;
          max-width: 580px;
          color: var(--color-white);
          font-size: 24px;
          line-height: 145%;
          @include s-1920 {
            max-width: 480px;
            font-size: 20px;
            padding-top: 40px;
          }
          @include s-1440 {
            max-width: 380px;
            font-size: 18px;
            padding-top: 30px;
          }
          @include s-1024 {
            font-size: 14px;
            padding-top: 25px;
          }
          @include s-640 {
            padding-top: 15px;
            max-width: 100%;
          }
        }
        &-link {
          padding-top: 70px;
          max-width: 320px;
          @include s-1920 {
            max-width: 250px;
            padding-top: 50px;
          }
          @include s-1440 {
            max-width: 200px;
          }
          @include s-1024 {
            max-width: 190px;
          }
        }
      }
    }
    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
        display: none;
        @include s-640 {
          display: block;
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: right;
      }
    }
  }
  .s-steps {
    padding-top: 150px;
    @include s-1920 {
      padding-top: 100px;
    }
    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      margin: 0 auto;
      max-width: 2240px;
      @include s-1920 {
        max-width: 1706px;
      }
      @include s-1440 {
        max-width: 1360px;
      }
      @include s-1024 {
        max-width: 100%;
      }
      @include s-768 {
        max-width: 580px;
      }
      @include s-640 {
        padding-left: 30px;
        padding-right: 30px;
        max-width: 100%;
      }
    }
    &-el {
      display: flex;
      flex-wrap: nowrap;
      padding-top: 210px;
      @include s-1920 {
        padding-top: 150px;
      }
      @include s-1440 {
        padding-top: 100px;
      }
      @include s-1024 {
        padding-top: 120px;
      }
      @include s-768 {
        flex-wrap: wrap;
      }
      &-desc {
        flex: 0 0 50%;
        max-width: 50%;
        padding-top: 40px;
        padding-right: 220px;
        @include s-1920 {
          padding-right: 120px;
        }
        @include s-1440 {
          padding-right: 60px;
        }
        @include s-1024 {
          padding-right: 40px;
        }
        @include s-768 {
          flex: 0 0 100%;
          max-width: 100%;
          padding-right: 0;
        }
        .item {
          &-icon {
            border-radius: 50%;
            color: var(--color-green-1);
            border: 2px solid var(--color-green-1);
            text-transform: uppercase;
            font-family: var(--font-bold-tele);
            font-size: 16px;
            padding: 7px 17px;
            line-height: 150%;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 110px;
            width: 100%;
            @include s-1920 {
              font-size: 14px;
              max-width: 95px;
            }
            @include s-1440 {
              font-size: 12px;
              max-width: 84px;
            }
            @include s-1024 {
              font-size: 11px;
              max-width: 80px;
            }
            @include s-640 {
              max-width: 70px;
              flex: 0 0 70px;
              height: 30px;
              padding: 0;
            }
          }
          &-li {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            &-icon {
              display: block;
              width: 38px;
              height: 38px;
              @include s-1920 {
                width: 32px;
                height: 32px;
              }
              @include s-1440 {
                width: 22px;
                height: 22px;
              }
              @include s-1024 {
              }
              @include s-768 {
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
            &-text {
              display: block;
              padding-left: 24px;
              @include s-1920 {
                padding-left: 30px;
              }
              @include s-1440 {
                padding-left: 18px;
              }
            }
          }
        }

        h2 {
          padding-top: 60px;
          @include s-1920 {
            padding-top: 40px;
          }
          @include s-1440 {
            padding-top: 30px;
          }
          @include s-1024 {
            padding-top: 25px;
          }
          @include s-768 {
            padding-top: 30px;
          }
        }
        ul {
          padding-top: 40px;
        }
        li {
          padding-top: 50px;
          font-size: 24px;
          line-height: 150%;
          @include s-1920 {
            font-size: 20px;
          }
          @include s-1440 {
            font-size: 18px;
          }
          @include s-1024 {
            font-size: 14px;
          }
          @include s-768 {
          }
          &:first-of-type {
            padding-top: 0;
          }
        }
      }
      &-img {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 220px;
        @include s-1920 {
          padding-left: 120px;
        }
        @include s-1440 {
          padding-left: 60px;
        }
        @include s-1024 {
          padding-left: 40px;
        }
        @include s-768 {
          flex: 0 0 100%;
          max-width: 100%;
          padding-left: 0;
        }
        img {
          width: 100%;
        }
      }
      &:first-of-type {
        padding-top: 0;
      }
      &:nth-of-type(2n) {
        .s-steps-el {
          &-desc {
            padding-left: 220px;
            padding-right: 0;
            @include s-1920 {
              padding-left: 120px;
            }
            @include s-1440 {
              padding-left: 60px;
            }
            @include s-1024 {
              padding-left: 40px;
            }
            @include s-768 {
              padding-left: 0;
            }
          }
          &-img {
            order: -1;
            padding-right: 220px;
            padding-left: 0;
            @include s-1920 {
              padding-right: 120px;
            }
            @include s-1440 {
              padding-right: 60px;
            }
            @include s-1024 {
              padding-right: 40px;
            }
            @include s-768 {
              order: 1;
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  .c-start {
    padding: 300px 50px 50px;
    @include s-1920 {
      padding-top: 200px;
    }
    @include s-1440 {
      padding: 180px 40px 40px;
    }
    @include s-1024 {
      padding: 100px 40px 40px;
    }
    @include s-640 {
      padding: 100px 15px 40px;
    }
    .c-start {
      &-item {
        max-width: 1440px;
        margin: 0 auto;
        @include s-1920 {
          max-width: 1024px;
        }
        @include s-1440 {
          max-width: 900px;
        }
        @include s-1024 {
          max-width: 620px;
        }
        @include s-768 {
          max-width: 500px;
        }
      }
    }
  }
  .s-note {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 170px;
    padding-bottom: 220px;
    @include s-1920 {
      padding-top: 100px;
      padding-bottom: 150px;
    }
    @include s-1440 {
      padding-top: 60px;
      padding-bottom: 100px;
    }
    @include s-1024 {
      padding-top: 40px;
      padding-bottom: 80px;
    }
    @include s-768 {
    }
    @include s-640 {
      padding-top: 20px;
      padding-bottom: 60px;
    }
    &-wrap {
      margin: 0 auto;
      max-width: 1110px;
      @include s-1440 {
        max-width: 1000px;
      }
      @include s-1024 {
        max-width: 700px;
      }
      @include s-768 {
        max-width: 500px;
      }
      @include s-640 {
        max-width: 100%;
      }
    }
  }
}
