.p-form {
  & .s-hero {
    position: relative;
    height: 810px;

    @include s-1920 {
      height: 640px;
    }

    @include s-1440 {
      height: 460px;
    }

    @include s-1024 {
      height: 360px;
    }

    @include s-640 {
      height: 310px;
    }

    &-wrap {
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      z-index: 1;
      max-width: 2240px;
      margin: 0 auto;
      height: 100%;

      @include s-1920 {
        max-width: 1700px;
      }

      @include s-1440 {
        max-width: 1360px;
      }

      @include s-1024 {
        max-width: 100%;
      }

      @include s-640 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    &-desc {
      flex: 1;

      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include s-768 {
        //   padding-right: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .item {
        max-width: 1080px;
        // @include s-1920 {
        //   max-width: 602px;
        // }
        // @include s-1440 {
        //   max-width: 570px;
        // }
        // @include s-1024 {
        //   max-width: 517px;
        // }
        @include s-768 {
          max-width: 508px;
        }
        @include s-640 {
          max-width: 100%;
        }
        h1 {
          color: var(--color-white);
        }
        &-desc {
          padding-top: 50px;
          max-width: 580px;
          color: var(--color-white);
          font-size: 24px;
          line-height: 145%;
          @include s-1920 {
            max-width: 480px;
            font-size: 20px;
            padding-top: 40px;
          }
          @include s-1440 {
            max-width: 380px;
            font-size: 18px;
            padding-top: 30px;
          }
          @include s-1024 {
            font-size: 14px;
            padding-top: 25px;
          }
          @include s-640 {
            padding-top: 15px;
            max-width: 100%;
          }
        }
        &-link {
          padding-top: 70px;
          max-width: 320px;
          @include s-1920 {
            max-width: 250px;
            padding-top: 50px;
          }
          @include s-1440 {
            max-width: 200px;
          }
          @include s-1024 {
            max-width: 190px;
          }
        }
      }
    }
    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
        display: none;
        @include s-768 {
          display: block;
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: right;
      }
    }
  }

  & .s-form {
    position: relative;
    text-align: center;
    padding: 250px 30px;

    @include s-1920 {
      padding: 200px 30px;
    }

    @include s-1440 {
      padding: 130px 30px;
    }

    @include s-1024 {
      padding: 90px 30px;
    }

    @include s-640 {
      padding: 60px 30px;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 120px;
      width: 100%;
      background: linear-gradient(180deg, #f4f4f4 3.54%, #a0971f 35.9%, #608c40 76.02%);
      filter: blur(81px);
    }

    & h3 {
      padding-bottom: 146px;

      @include s-1920 {
        padding-bottom: 116px;
      }

      @include s-1440 {
        padding-bottom: 102px;
      }

      @include s-1024 {
        padding-bottom: 68px;
      }

      @include s-768 {
        padding-bottom: 84px;
      }

      @include s-640 {
        padding-bottom: 48px;
      }
    }

    & form {
      max-width: 760px;
      width: 100%;
      margin: 0 auto;

      @include s-1920 {
        max-width: 540px;
      }

      @include s-1024 {
        padding: 90px 30px;
      }

      @include s-640 {
        max-width: 100%;
      }
    }
  }

  & .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
    min-width: 1000px;
    justify-content: center;
    background-color: #969913;
    font-size: 24px;
    line-height: 145%;

    @include s-1920 {
      min-width: 900px;
      font-size: 20px;
    }

    @include s-1440 {
      min-width: 710px;
      font-size: 18px;
    }

    @include s-1024 {
      min-width: 560px;
      font-size: 16px;
    }

    @include s-640 {
      min-width: 100%;
    }
  }
}
