.p-about {
  padding-top: 82px;
  @include s-1024{
    padding-top: 62px;
  }
  @include s-1024{
    padding-top: 46px;
  }
  .s{
    &-wrap{
      padding-left: 40px;
      padding-right: 40px;
      margin: 0 auto;
      max-width: 2240px;
      @include s-1920{
        max-width: 1706px;
      }
      @include s-1440{
        max-width: 1360px;
      }
      @include s-1024{
        max-width: 100%
      }
      @include s-640{
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  .s-hero{ 
    position: relative;
    height: 1000px;
    @include s-1920{
      height: 550px;
    }
    @include s-1440{
      height: 480px;
    }
    @include s-1024{
      height: 390px;
    }
    @include s-640{
      height: 260px;
    }
    &-wrap{
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      z-index: 1;
      max-width: 2240px;
      margin: 0 auto;
      height: 100%;
      @include s-1920{
        max-width: 1700px;
      }
      @include s-1440{
        max-width: 1360px;
      }
      @include s-1024{
        max-width: 100%;
      }
      @include s-640{
        padding-left: 30px;
        padding-right: 30px;
      }

    }
    &-desc{
      flex: 0 0 50%;
      max-width: 50%;
      flex: 1;
      padding-right: 190px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include s-768{
        padding-right: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item{
        max-width: 1080px;
        @include s-1920{
          max-width: 602px;
        }
        @include s-1440{
          max-width: 570px;
        }
        @include s-1024{
          max-width: 517px;
        }
        @include s-768{
          max-width: 508px;
        }
        @include s-640{
          max-width: 100%;
        }
        h1{
          color: var(--color-white)
        }
        &-desc{
          padding-top: 50px;
          max-width: 430px;
          color: var(--color-white);
          font-size: 24px;
          line-height: 145%;
          padding-top: 50px;
          max-width: 430px;
          color: var(--color-white);
          font-size: 24px;
          line-height: 145%;
          @include s-1920{
            max-width: 410px;
            font-size: 20px;
            padding-top: 40px;
          }
          @include s-1440{
            max-width: 380px;
            font-size: 18px;
            padding-top: 30px;
          }
          @include s-1024{
            font-size: 14px;
            padding-top: 25px;
          }
          @include s-640{
            padding-top: 15px;
            max-width: 100%;
          }
        }
      }

    }
    &-img{
      flex: 0 0 50%;
      max-width: 50%;
    }
    &-bg{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

  }
  .s-alta{
    padding-top: 220px;
    padding-bottom: 50px;

    @include s-1920{
      padding-top: 200px;
    }

    @include s-1440{
      padding-top: 100px;
    }

    @include s-1024{
      padding-top: 80px;
    }
    @include s-640{
      padding-top: 50px;
    }

    &-wrap{
      display: flex;
      flex-wrap: nowrap;
      padding-left: 40px;
      padding-right: 40px;
      margin: 0 auto;
      max-width: 2240px;
      @include s-1920{
        max-width: 1706px;
      }
      @include s-1440{
        max-width: 1360px;
      }
      @include s-1024{
        max-width: 100%
      }
      @include s-768{
        flex-wrap: wrap;
      }
      @include s-640{
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &-img{
      flex: 0 0 50%;
      max-width: 50%;
      @include s-768{
        flex: 0 0 100%;
        max-width: 100%;
      }
      img{
        width: 100%;
      }
    }
    &-desc{
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 220px;
      @include s-1920{
        padding-left: 120px;
      }
      @include s-1024{
        padding-left: 80px;
      }
      @include s-768{
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        padding-top: 60px;
      }
      @include s-640{
        padding-left: 15px;
        padding-right: 15px;
      }
      .item{
        @include s-768{
          max-width: 500px;
          margin: 0 auto;
        }
        h2{
          position: relative;
          span{
            display: inline-flex;
            border: 2px solid var(--color-black);
            border-radius: 100%;
            border: 2px solid var(--color-black);
          }
        }
        &-desc{
          padding-top: 60px;
          font-size: 24px;
          line-height: 145%;
          @include s-1920{
            font-size: 20px;
            padding-top: 35px;
          }
          @include s-1440{
            font-size: 18px;
          }
          @include s-1024{
            font-size: 14px;
          }
          @include s-640{
            padding-top: 15px;
          }
        }
      }
    }
  }
  .s-care{
    height: 1450px;
    overflow-x: hidden;
    position: relative;
    @include s-1920{
      height: 930px;
    }
    @include s-1024{
      height: 700px;
    }
     &-wrap{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 30px;
      padding-right: 30px;
      position: relative;
      z-index: 1;
      height: 100%;
    }
    &-item{
      max-width: 1768px;
      margin: 0 auto;
      text-align: center;
      @include s-1920{
        max-width: 1360px;
      }
      @include s-1440{
        max-width: 1060px;
      }
      @include s-1024{
        max-width: 804px;
      }
      @include s-768{
        max-width: 606px;
      }
      @include s-640{
        max-width: 100%;
      }
      h3{

      }
    }
    &-bg{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      padding-top: 200px;
      padding-bottom: 200px;
      @include s-1920{
        padding-top: 150px;
        padding-bottom: 150px;
      }
      @include s-1024{
        padding-top: 100px;
        padding-bottom: 100px;
      }
      &-grad{
        background: linear-gradient(180deg, #F4F4F4 2.38%, #D9A122 10.72%, #A0971F 49.5%, #608C40 91.54%, #F6F6F6 99.64%);
        filter: blur(50px);
        width: 100%;
        height: 100%;
        transform: scaleX(1.05);
      }
    }
  }
  .s-why{
    padding-top: 50px;
    padding-bottom: 300px;
    @include s-1920{
      padding-bottom: 250px;
    }
    @include s-1440{
      padding-bottom: 170px;
    }
    @include s-1024{
      padding-bottom: 120px;
    }
    &-wrap{
      display: flex;
      flex-wrap: nowrap;
      display: flex;
      flex-wrap: nowrap;
      padding-left: 40px;
      padding-right: 40px;
      margin: 0 auto;
      max-width: 2240px;
      @include s-1920{
        max-width: 1706px;
      }
      @include s-1440{
        max-width: 1360px;
      }
      @include s-1024{
        max-width: 100%
      }
      @include s-768{
        flex-wrap: wrap;
      }
      @include s-640{
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &-img{
      flex: 0 0 50%;
      max-width: 50%;
      @include s-768{
        flex: 0 0 100%;
        max-width: 100%;
        order: -1;
      }
      img{
        width: 100%;
      }
    }
    &-desc{
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 220px;
      @include s-1920{
        padding-right: 150px;
      }
      @include s-1440{

      }
      @include s-1024{
        padding-right: 80px;
      }
      @include s-768{
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0; 
        padding-top: 60px;       
      }
      @include s-640{
        padding-top: 50px;
        padding-left: 15px;
        padding-right: 15px;
      }
      .item{
        @include s-768{
          max-width: 500px;
          margin: 0 auto;
        }
        h2{
          position: relative;
          span{
            display: inline-flex;
            border: 2px solid var(--color-black);
            border-radius: 100%;
            border: 2px solid var(--color-black);
          }
        }
        &-subtitle{
          padding-top: 60px;
          font-size: 42px;
          line-height: 145%;
          font-family: var(--font-regular-tele);
          @include s-1920{
            font-size: 34px;
            padding-top: 40px;
          }
          @include s-1440{
            font-size: 28px;
          }
          @include s-1024{
            font-size: 24px;
            padding-top: 35px;
          }
          @include s-640{
            padding-top: 15px;
            font-size: 20px;
          }
        }
        &-desc{
          padding-top: 60px;
          font-size: 24px;
          line-height: 145%;
          @include s-1920{
            font-size: 20px;
            padding-top: 35px;
          }
          @include s-1440{
            font-size: 18px;
            padding-top: 25px;
          }
          @include s-1024{
            font-size: 14px;
            padding-top: 20px;
          }
          @include s-640{
            padding-top: 15px;
          }
        }

      }
    }
  }
  .s-competent{
    position: relative;
    padding-top: 220px;
    padding-bottom: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 1150px;
    @include s-1920{
      min-height: 940px;
      padding-top: 170px;
      padding-bottom: 170px;
    }
    @include s-1440{
      min-height: 647px;
      padding-top: 110px;
      padding-bottom: 110px;
    }
    @include s-1024{
      min-height: 620px;
    }
    @include s-768{
      min-height: 870px;
      padding-top: 175px;
      padding-bottom: 175px;
    }
    @include s-640{
      min-height: 700px;
      padding-top: 90px;
      padding-bottom: 90px;
    }
    .item{
      position: relative;
      z-index: 1;
      text-align: center;
      color: var(--color-white);
      max-width: 1440px;
      margin: 0 auto;
      height: 100%;
      @include s-1920{
        max-width: 1282px;
      }
      @include s-1440{
        max-width: 960px;
      }
      @include s-1024{
        max-width: 784px;
      }
      @include s-768{
        max-width: 546px;
      }
      @include s-640{
        max-width: 100%;
      }
      &-icon{

      }
      &-title{
        padding-top: 80px;
 
      }
      &-subtitle{
        padding-top: 60px;
        color: var(--color-white);
        font-size: 34px;
        line-height:145%;
        max-width: 1440px;
        margin: 0 auto;
        @include s-1920{
          font-size: 24px;
          margin: 0 auto;
          max-width: 826px;
        }
        @include s-1440{
          font-size: 20px;
          max-width: 780px;
          padding-top: 40px;

        }
        @include s-1024{
          font-size: 16px;
          max-width: 642px;
        }
        @include s-768{
           max-width: 500px;
        }
        @include s-640{
          font-size: 14px;
          max-width: 292px;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
      &-bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        img{
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

  } 
  .s-team{
    padding-top: 250px;
    padding-bottom: 220px;
    @include s-1920{
        padding-bottom: 180px;
    }
     
    @include s-1440{
      padding-top: 180px;
      padding-bottom: 120px;
    }

    @include s-1024{
      padding-top: 120px;
      padding-bottom: 80px;
    }

    @include s-640{
      padding-top: 100px;
      padding-bottom: 60px;
    }
    &-wrap{
      padding-left: 40px;
      padding-right: 40px;
      max-width: 2260px;
      margin:  0 auto;
      @include s-1920{
        max-width: 1700px;
      }
      @include s-1440{
        max-width: 1360px;
      }
      @include s-640{
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    &-top{
      display: flex;
      justify-content: space-between;
      align-items: center;

    }
    &-content{
      padding-top: 65px;
      ul{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -24px;
        @include s-1440{
          margin: 0 -14px;
        }
        @include s-1024{
          margin: 0 -17px;
        }
        @include s-768{
          margin: 0 -19px;
        }
        @include s-640{
          margin: 0;
        }
      }
      li{
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 80px;
        flex:  0 0 33.33%;
        max-width: 33.33%;
        @include s-1920{
          padding-bottom: 70px;
        }
        @include s-1440{
          padding-left: 14px;
          padding-right: 14px; 
          padding-bottom: 60px;
        }
        @include s-1024{
          padding-left: 17px;
          padding-right: 17px; 
          padding-bottom: 40px;
        }
        @include s-768{
          flex:  0 0 50%;
          max-width: 50%;
          padding-left: 19px;
          padding-right: 19px; 

        }
        @include s-640{
          flex:  0 0 100%;
          max-width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }
      .item{
        &-img{
          position: relative;
          height: pxVw(686,2560); 
          // height: 686px;
          @include s-1920{
            // height: 506px;
            height: pxVw(506,1920); 
          }
          @include s-1440{
            height: 408px;
          }
          @include s-1024{
            // height: 408px;
            height: pxVw(408,1024); 
          }
          @include s-768{
            // height: 326px;
            height: pxVw(326,768);  
          }
          @include s-640{
            // height: 315px;
            height: pxVw(315,375);
          }
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        &-hover{
          display: flex;
          align-items: flex-end;
          padding: 30px 25px;
          background: linear-gradient(180deg, rgba(0, 4, 3, 0) 0%, rgba(0, 4, 3, 0.470234) 37.91%, #000403 100%);
          color: var(--color-white);
          position:absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          &:hover{
            opacity: 1;
          }
          &-wrap{

          }
          &-title{
            font-family: var(--font-bold-tele);
            font-size: 20px;
            line-height: 150%;
          }
          &-text{
            color: var(--color-white);
            padding-top: 40px;
            font-size: 20px;
            line-height: 150%;
          }
        }
        &-name{
          padding-top: 30px;
          font-family: var(--font-regular-tele);
          font-size: 42px;
          @include s-1920{
            font-size: 34px;
            padding-top: 25px;
          }
          @include s-1440{
            padding-top: 20px;
            font-size: 28px;
          }
          @include s-1024{
            padding-top: 15px;
            font-size: 24px;
          }
          @include s-640{
            font-size: 20px;
          }
        }
        &-position{
          padding-top: 5px;
          color: var(--color-grey);
          font-size: 24px;
          @include s-1920{
            font-size: 20px;
          }
          @include s-1440{
            font-size: 18px;
          }
          @include s-1024{
            font-size: 14px;
          }
        }
      }
    }
  }
  
}
